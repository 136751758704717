<template>
  <div class="repairitFeature">

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <div class="btn">
          <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
        <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/></div>
      </div>

      <!--数据列表-->
      <el-table
        :data="data.results"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="file_format"
          label="format"
          :width="120"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="width"
          label="width"
          align="center"
          :width="80"
        >
        </el-table-column>
        <el-table-column
          prop="height"
          label="height"
          align="center"
          :width="80">
        </el-table-column>
        <el-table-column
          prop="make"
          label="make"
          :width="180">
        </el-table-column>
        <el-table-column
          prop="model"
          label="model"
          :width="280">
        </el-table-column>
        <el-table-column
          prop="video_codec"
          label="codec"
          :width="80">
        </el-table-column>
        <el-table-column
          prop="download_url"
          label="download_url">
        </el-table-column>
         <el-table-column width="100"
          prop="create_by"
          align="center"
          :formatter="formatterEmpty"
          label="创建者">
        </el-table-column> 
       <el-table-column width="100"
          prop="update_by"
          align="center"
          :formatter="formatterEmpty"
          label="更新者">
        </el-table-column>
        <el-table-column
          prop="update_time"
          label="更新时间"
          align="center"
          :formatter="formatterDateTime"
          :width="180">
        </el-table-column>
        <el-table-column label="操作" :width="120" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditCloudTraitInfo(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteCloudTraitInfo(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="format: " prop="fileFormat">
          <el-input :disabled="operType" v-model="addForm.fileFormat" placeholder="请输入文件格式"/>
        </el-form-item>

        <el-form-item label="height*width: " class="preview-file">
          <el-input :disabled="operType" v-model="addForm.height" placeholder="请输入长度" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"></el-input>
          <span> * </span>
          <el-input :disabled="operType" v-model="addForm.width" placeholder="请输入宽度" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"></el-input>
        </el-form-item>

        <el-form-item label="make: " prop="make">
          <el-input :disabled="operType" v-model="addForm.make" placeholder="请输入制造商"/>
        </el-form-item>

        <el-form-item label="mode: " prop="vmodel">
          <el-input :disabled="operType" v-model="addForm.vmodel" placeholder="请输入型号"/>
        </el-form-item>

        <el-form-item label="video codec: " prop="videoCodec">
          <el-input :disabled="operType" v-model="addForm.videoCodec" placeholder="请输入编码格式"/>
        </el-form-item>


        <el-form-item label="download url: " prop="downloadUrl">
          <el-input v-model="addForm.downloadUrl" placeholder="请输入特征下载地址"/>
        </el-form-item>

      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddCloudTraitInfo" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {addCloudTraitInfo, delCloudTraitInfo, getCloudTraitInfo} from "@/api/cloudtrait";
  import UploadExcelComp from "@/components/common/UploadExcel"
  import {formatDate} from "@/utils/date"
  export default {
  name: "repairitFeature",
  components: {
    UploadExcelComp
  },
  data() {
    return {
      form: {
        name: ''
      },
      title: "",     // 弹出层标题
      loading: true, // 表单数据加载中
      data: [],      // 云端媒体特征信息配置列表
      page: 1,       // 当前页码
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      // 添加媒体云端特征信息
      addForm:{
        fileFormat: "",
        width: 0,
        height: 0,
        make: "",
        vmodel: "",
        videoCodec: "",
        downloadUrl: "",
      },
      addFormRules: {
        fileFormat: [{required: true, trigger: 'blur', message: "format不能为空"}],
        make:[{required: true, trigger: 'blur', message: "make不能为空"}],
        vmodel:[{required: true, trigger: 'blur', message: "model不能为空"}],
        videoCodec:[{required: true, trigger: 'blur', message: "video codec不能为空"}],
        downloadUrl:[{required: true, trigger: 'blur', message: "download url不能为空"}]
      }
    }
  },
  created() {
    this.loadCloudTraitInfo()
  },
  methods: {
    // 表单重置
    reset() {
      this.addForm = {
        fileFormat: "",
        width: 0,
        height: 0,
        make: "",
        vmodel: "",
        videoCodec: "",
        downloadUrl: "",
      }
    },
    loadCloudTraitInfo(page = 1) {
      this.loading = true
      getCloudTraitInfo({
        page,
        "count": this.pageSize
      }).then(res => {
        this.data = res.data
        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadCloudTraitInfo(page)
    },

    /**
     *  新增云端特征信息
     */
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加云端特征信息"
    },
    /**
     * 删除云端特征信息
     */
    onDeleteCloudTraitInfo(row) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delCloudTraitInfo({
          "file_format": row.file_format,
          "width": row.width,
          "height": row.height,
          "make": row.make,
          "model": row.model,
          "video_codec": row.video_codec
        }).then(res => {
          this.$message({
            message: '恭喜您，删除成功',
            type: 'success'
          });
          //  删除成功
          this.loadCloudTraitInfo(this.page)
        })
      }).catch(() => {});
    },
    /**
     *  修改云端特征信息
     */
    onEditCloudTraitInfo(data) {
      this.reset()
      this.operType = true
      this.dialogFormVisible = true
      this.addForm.fileFormat = data.file_format
      this.addForm.height = data.height
      this.addForm.width = data.width
      this.addForm.make = data.make
      this.addForm.vmodel = data.model
      this.addForm.videoCodec = data.video_codec
      this.addForm.downloadUrl = data.download_url
      this.title = "修改云端特征信息"
    },
    /**
     * 添加云端特征信息
     */
    onAddCloudTraitInfo() {
      this.$refs["addFormRef"].validate(valid => {
        if (valid) {
          this.loading = true
          addCloudTraitInfo({
            "file_format": this.addForm.fileFormat,
            "width": parseInt(this.addForm.width),
            "height": parseInt(this.addForm.height),
            "make": this.addForm.make,
            "model": this.addForm.vmodel,
            "video_codec": this.addForm.videoCodec,
            "download_url": this.addForm.downloadUrl,
          }).then(res => {
            //  添加成功，刷新列表
            this.loadCloudTraitInfo(this.page)

            this.loading = false
            this.$message({
              message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功',
              type: 'success'
            });
          }).catch(err => {
            this.loading = false
            this.$message({
              message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
              type: 'warning'
            });
          })
        }
      })
    },
    beforeUpload(file) {   //导入
      var a = file
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: '请不要上传大于1m的文件!',
        type: 'warning'
      })
      return false
    },

    handleSuccess({ results, header }) {  //导入内容
      if (header[0] != "file_format" || header[1] != "width" || header[2] != "height" || header[3] != "make" || header[4] != "model" || header[5] != "video_codec" || header[6] != "download_url")  {
        this.$message({
          message: '参数列表错误，请按对应格式进行导入【表头：file_format\twidth\theight\tmake\tmodel\tvideo_codec\tdownload_url】',
          type: 'warning'
        })
        return
      }
      var params = {
        "datas": results
      }
      console.log("传入参数",params)
      this.loading = true
      addCloudTraitInfo(params).then(response => {
        //  添加成功，刷新列表
        this.loadCloudTraitInfo(this.page)
        this.loading = false
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
      })
    },
    //为空转换
    formatterEmpty(row, column, v){
      if (v || (v === 0)) return v
      return "-"
    },
    //时间转换
    formatterDateTime(row, column, v){
      if (!v) return "-"
      if(String(v).length === 10) v = v * 1000
      let date = new Date(v)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }  
  }
}
</script>

<style scoped>

.btn {
  display: flex;
}

.preview-file .el-input{
  width: 103px;
}
.list-table {
  margin-bottom: 10px;
}
</style>
