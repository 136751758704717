<template>
  <div class="ads-recoverit-contarner">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid" clearable></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{total || 0}} 条结果</span>
        <div class="btn">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="onHandleAdd"
          >添加</el-button>
          <!-- <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/> -->
        </div>
      </div>
      <!--数据列表-->
      <el-table
        :data="data"
        :span-method="objectSpanMethod"
        border
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="Pid"
          label="产品PID"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="UserType"
          align="center"
          width="100"
          label="账户类型">
          <template slot-scope="scope">
            {{scope.row.UserType === 0 ? "试用" : "付费" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="UserType"
          align="center"
          width="100"
          label="广告位置">
          <template slot-scope="scope">
            {{scope.row.position_type === 'ExitAds' ? "退出" : scope.row.position_type === 'RightAds'? '右侧' : '左侧' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="country"
          label="地区">
          <template slot-scope="scope">
            <p>{{resultCountry(scope.row.country)}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="图片地址">
          <template slot-scope="scope">
            <p v-for="item in scope.row.img_url.split(',')">{{item || '-'}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="图片跳转地址">
          <template slot-scope="scope">
            <p v-for="item in scope.row.img_jump_url.split(',')">{{item || '-'}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="生效时间"
          align="center"
          width="160"
        >
          <template slot-scope="scope">
            <p>起：{{resultDateTime(scope.row.start_at)}}</p>
            <p>止：{{resultDateTime(scope.row.end_at)}}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="140">
          <template slot-scope="scope">
            <el-switch
              @change="changeStatus(scope.row)"
              v-model="scope.row.is_enable"
              :active-value='1'
              :inactive-value='0'
              active-text="启用"
              inactive-text="停用">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          prop="create_by"
          label="创建人"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="update_by"
          label="更新人"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            {{ scope.row.update_by || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          label="更新时间"
          align="center"
          width="140"
        >
          <template slot-scope="scope">
            <p>{{resultDateTime(scope.row.update_time)}}</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditAds(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteAds(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加 -->
    <el-dialog custom-class="dr-dialog ads-dialog" :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="1020px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="产品PID: " prop="Pid">
          <el-select
            v-model="addForm.Pid"
            multiple
            filterable
            allow-create
            default-first-option
            placeholder="请选择产品PID">
            <el-option
              v-for="item in optionsPid"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账户类型" prop="UserType">
          <el-select v-model="addForm.UserType" placeholder="默认全部类型" :disabled="operType">
            <el-option
              v-for="item in optionsUserType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <span style="float: left">{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <div class="position">
          <p>左侧广告位</p>
          <el-form-item label="上线地区" prop="Country">
            <el-select v-model="addForm.left.Country" multiple filterable placeholder="默认全部地区">
              <el-option
                v-for="item in optionsCountry"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <div class="tab-list-info">
            <div v-for="(item, index) in addForm.left.list">
              <el-form-item required>
                <span slot="label">图片
                  <el-popover
                    placement="top"
                    width="200"
                    trigger="hover"
                    content="须上传宽212px*高280px或者同宽高比的JPG/JPEG/PNG图">
                    <i class="el-icon-warning" style="color: #999" slot="reference"></i>
                  </el-popover>
                </span>
                <div class="dr-upload">
                  <i v-if="!item.ImgUrl" slot="default" class="el-icon-upload2"></i>
                  <img
                    v-if="item.ImgUrl"
                    class="el-upload-list__item-thumbnail"
                    :src="item.ImgUrl" alt=""
                  >
                  <input :ref="'file_left' + index" class="input-file" type="file" accept=".jpg,.jpeg,.png" title="" @change="uploadImg(index, 'left')">
                </div>
                
                <el-form-item label="跳转地址" style="display: inline-block; width: 272px;">
                  <el-input v-model="item.ImgJumpUrl"></el-input>
                </el-form-item>
                <div class="el-form-item__error" v-show="addForm.left.checking">
                  <template v-if="item.ImgExtNot">图片格式不符</template>
                  <template v-else-if="item.ImgWhNot">图片宽高比不符</template>
                </div>
              </el-form-item>
              <i class="remove el-icon-remove" v-if="addForm.left.list.length > 1" @click="removeTab(index, 'left')"></i>
              <i class="add el-icon-circle-plus" @click="addTab(index, 'left')"></i>
            </div>
          </div>
          <el-form-item label="时间" required>
            <el-date-picker
              v-model="addForm.left.date"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="position">
          <p>右侧广告位</p>
          <el-form-item label="上线地区" prop="Country">
            <el-select v-model="addForm.right.Country" multiple filterable placeholder="默认全部地区">
              <el-option
                v-for="item in optionsCountry"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <div class="tab-list-info">
            <div v-for="(item, index) in addForm.right.list">
              <el-form-item required>
                <span slot="label">图片
                  <el-popover
                    placement="top"
                    width="200"
                    trigger="hover"
                    content="须上传JPG/JPEG/PNG图">
                    <i class="el-icon-warning" style="color: #999" slot="reference"></i>
                  </el-popover>
                </span>
                <div class="dr-upload">
                  <i v-if="!item.ImgUrl" slot="default" class="el-icon-upload2"></i>
                  <img
                    v-if="item.ImgUrl"
                    class="el-upload-list__item-thumbnail"
                    :src="item.ImgUrl" alt=""
                  >
                  <input :ref="'file_right' + index" class="input-file" type="file" accept=".jpg,.jpeg,.png" title="" @change="uploadImg(index, 'right')">
                </div>
                <el-form-item label="跳转地址" style="display: inline-block; width: 272px;">
                  <el-input v-model="item.ImgJumpUrl"></el-input>
                </el-form-item>
                <div class="el-form-item__error" v-show="addForm.right.checking">
                  <template v-if="item.ImgExtNot">图片格式不符</template>
                  <template v-else-if="item.ImgWhNot">图片宽高比不符</template>
                </div>
              </el-form-item>
              <i class="remove el-icon-remove" v-if="addForm.right.list.length > 1" @click="removeTab(index, 'right')"></i>
              <i class="add el-icon-circle-plus" @click="addTab(index, 'right')"></i>
            </div>
          </div>
          <el-form-item label="时间" required>
            <el-date-picker
              v-model="addForm.right.date"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="position">
          <p>退出广告位</p>
          <el-form-item label="上线地区" prop="Country">
            <el-select v-model="addForm.exit.Country" multiple filterable placeholder="默认全部地区">
              <el-option
                v-for="item in optionsCountry"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <div class="tab-list-info">
            <div v-for="(item, index) in addForm.exit.list">
              <el-form-item required>
                <span slot="label">图片
                  <el-popover
                    placement="top"
                    width="200"
                    trigger="hover"
                    content="须上传JPG/JPEG/PNG图">
                    <i class="el-icon-warning" style="color: #999" slot="reference"></i>
                  </el-popover>
                </span>
                <div class="dr-upload">
                  <i v-if="!item.ImgUrl" slot="default" class="el-icon-upload2"></i>
                  <img
                    v-if="item.ImgUrl"
                    class="el-upload-list__item-thumbnail"
                    :src="item.ImgUrl" alt=""
                  >
                  <input :ref="'file_exit' + index" class="input-file" type="file" accept=".jpg,.jpeg,.png" title="" @change="uploadImg(index, 'exit')">
                </div>
                <el-form-item label="跳转地址" style="display: inline-block; width: 272px;">
                  <el-input v-model="item.ImgJumpUrl"></el-input>
                </el-form-item>
                <div class="el-form-item__error" v-show="addForm.exit.checking">
                  <template v-if="item.ImgExtNot">图片格式不符</template>
                </div>
              </el-form-item>
              <i class="remove el-icon-remove" v-if="addForm.exit.list.length > 1" @click="removeTab(index, 'exit')"></i>
              <i class="add el-icon-circle-plus" @click="addTab(index, 'exit')"></i>
            </div>
          </div>
          <el-form-item label="时间" required>
            <el-date-picker
              v-model="addForm.exit.date"
              type="datetimerange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddAds" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {addAds, delAds, getAdsList, adsImg} from "@/api/ads";
  import UploadExcelComp from "@/components/common/UploadExcel";
  import {formatDate} from "@/utils/date"
  import {country} from "@/utils/country"
  export default {
    name: "adsRepairit",
    components: {
      UploadExcelComp
    },
    data() {
      return {
        form: {
          name: ''
        },
        pid: null,
        optionsPid: [
        {
            value: '5913',
            label: '5913'
          },
          {
            value: '5914',
            label: '5914'
          },
          {
            value: '6062',
            label: '6062'
          },
          {
            value: '6063',
            label: '6063'
          },
          {
            value: '6064',
            label: '6064'
          },
          {
            value: '6065',
            label: '6065'
          },
          {
            value: '6066',
            label: '6066'
          },
          {
            value: '6067',
            label: '6067'
          },
          {
            value: '6068',
            label: '6068'
          },
          {
            value: '6069',
            label: '6069'
          },
          {
            value: '6070',
            label: '6070'
          },
          {
            value: '6071',
            label: '6071'
          }
        ],
        optionsCountry: [{
          value: '1',
          label: '美国'
        }],
        optionsUserType: [
          {
            value: 2,
            label: '全部账户'
          },
          {
            value: 0,
            label: '试用账户'
          },
          {
            value: 1,
            label: '付费账户'
          }
        ],
        title: "添加运营位",     // 弹出层标题
        loading: true, // 表单数据加载中
        data: [],      // 列表
        total: 0,      // 总数
        page: 1,       // 当前页码
        pageSize: 20,  // 每页大小
        dialogFormVisible: false, // 控制对话框的显示隐藏
        operType: false, // 操作类型
        mulEdit: false,
        mulSel: 'none', // 多选按钮显示/隐藏样式
        muldata: [],
        // 添加用户信息
        addForm:{
          Pid: '', // 产品PID
          UserType: '',
          left: {
            checking: false,
            Country: [],
            list: [{
              ImgUrl: '',
              ImgJumpUrl: '',
            }],
            date: '',
            IsEnable: 0
          },
          right: {
            checking: false,
            Country: [],
            list: [{
              ImgUrl: '',
              ImgJumpUrl: '',
            }],
            date: '',
            IsEnable: 0
          },
          exit: {
            checking: false,
            Country: [],
            list: [{
              ImgUrl: '',
              ImgJumpUrl: '',
            }],
            date: '',
            IsEnable: 0
          },
        },
        addFormRules: {
          Pid: [{required: true, trigger: 'blur', message: "PID不能为空"}]
        },
        pickerOptions: {
          shortcuts: [{
            text: '一周',
            onClick(picker) {
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '一个月',
            onClick(picker) {
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '三个月',
            onClick(picker) {
              const start = new Date(new Date().setHours(0, 0, 0, 0));
              const end = new Date(new Date().setHours(23, 59, 59, 999));
              end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        rowSpanArr: [],
      }
    },
    created() {
      this.loadAdsInfo()
      this.optionsCountry = country()
    },
    methods: {
      // 表单重置
      reset() {
        if(this.$refs['addFormRef']) this.$refs['addFormRef'].resetFields()
        this.addForm = this.$options.data().addForm
      },
      loadAdsInfo(page = 1) {
        this.loading = true
        getAdsList({
          "product_type": 1,
          page,
          "count": this.pageSize,
          "version": "v2.2",
          "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
        }).then(res => {
          let list = [], type = ['LeftAds', 'RightAds', 'ExitAds']
          res.data.results.forEach(item=>{
            type.forEach(name=>{
              if(item[name].img_url){
                list.push({
                  position_type: name,
                  Pid: item.Pid,
                  ProductType: item.ProductType,
                  UserType: item.UserType,
                  country: item[name].country,
                  img_url: item[name].img_url,
                  img_jump_url: item[name].img_jump_url,
                  start_at: item[name].start_at,
                  end_at: item[name].end_at,
                  is_enable: item[name].is_enable,
                  create_by: item.create_by,
                  create_time: item.create_time,
                  update_by: item.update_by,
                  update_time: item.update_time,
                  LeftAds: item.LeftAds,
                  RightAds: item.RightAds,
                  ExitAds: item.ExitAds,
                })
              }
            })
          })
          this.data = list
          this.getRowSpan()
          this.total = res.data.total
          //  关闭加载中
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      getRowSpan() {
        this.rowSpanArr = [];
        this.rowSpanArr2 = [];
        this.data.forEach((item, index) => {
          if (index == 0) {
            this.rowSpanArr.push(1);
            this.rowSpanArr2.push(1);
            this.position = 0;
            this.position2 = 0;
          } else {
            if (this.data[index].Pid == this.data[index - 1].Pid) {
              this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
              this.rowSpanArr.push(0);
              this.data[index].Pid = this.data[index - 1].Pid;
            } else {
              this.rowSpanArr.push(1);
              this.position = index;
            }
            if ((this.data[index].Pid == this.data[index - 1].Pid) && (this.data[index].UserType == this.data[index - 1].UserType)) {
              this.rowSpanArr2[this.position2] += 1; //项目名称相同，合并到同一个数组中
              this.rowSpanArr2.push(0);
              this.data[index].UserType = this.data[index - 1].UserType;
            } else {
              this.rowSpanArr2.push(1);
              this.position2 = index;
            }
          }
        });
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // 只合并区域位置
        if (columnIndex === 0) {
          const _row = this.rowSpanArr[rowIndex];
          return {
            rowspan: _row, //行
            colspan: 1 //列
          };
        }else if(columnIndex === 1 || columnIndex === 8 || columnIndex === 9 || columnIndex === 10 || columnIndex === 11){
          const _row = this.rowSpanArr2[rowIndex];
          return {
            rowspan: _row, //行
            colspan: 1 //列
          };
        }
      },
      handleSelectionChange(val) {
        this.mulEdit = true
        this.mulSel = val.length > 0 ? '' : 'none'
        this.muldata = val
      },
      /**
       * 查询
       */
      onSearch() {
        this.loadAdsInfo()
      },
      /**
       *  分页按钮响应交互
       */
      onCurrentChange(page) {
        this.loadAdsInfo(page)
      },
      /**
       *  新增运营位信息
       */
      onHandleAdd(str) {
        this.reset()
        this.operType = false
        this.dialogFormVisible = true
        this.is_add = true
        this.mulEdit = false
        this.title = "添加运营位信息"
      },
      /**
       * 删除运营位信息
       */
      onDeleteAds(item) {
        this.$confirm('确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delAds({
            product_type: 1,
            pid: item.Pid,
            UserType: item.UserType
          }).then(res => {
            //  删除成功
            this.loadAdsInfo(this.page)
          })
        }).catch(() => {});
      },
      /**
       *  修改用户运营位信息
       */
      onEditAds(data) {
        this.reset()
        let imgList = [[], [], []], ImgUrlArr = [], ImgJumpUrlArr = []
        let type = ['LeftAds', 'RightAds', 'ExitAds']
        type.forEach((name, nameIndex)=>{
          ImgUrlArr = data[name].img_url.split(',')
          ImgJumpUrlArr = data[name].img_jump_url.split(',')
          ImgUrlArr.forEach((item, index)=>{
            imgList[nameIndex].push({
              ImgUrl: item,
              ImgJumpUrl: ImgJumpUrlArr[index]
            })
          })
        })
        let typeKey = ['left', 'right', 'exit']
        this.addForm = {
          Pid: [data.Pid], // 产品PID
          UserType: data.UserType,
          left: {},
          right: {},
          exit: {}
        }
        typeKey.forEach((key, index)=>{
          this.addForm[key] = {
            Country: data[type[index]].country ? data[type[index]].country.split(',') : [],
            list: imgList[index],
            date: [
              data[type[index]].start_at || '',
              data[type[index]].end_at || ''
            ],
            IsEnable: data[type[index]].is_enable
          }
        })
        this.title = "修改运营位信息"
        this.operType = true
        this.dialogFormVisible = true
        this.is_add = true
        this.mulEdit = false
      },
      /**
       * 添加/修改运营位信息
       */
      onAddAds() {
        this.$refs["addFormRef"].validate(valid => {
          if (valid) {
            let ImgUrl = [[], [], []], ImgJumpUrl = [[], [], []], imgValid = [true, true, true], typeKey = ['left', 'right', 'exit'], emptyDateType = []
            typeKey.forEach((key, index)=>{
              this.$set(this.addForm[key], 'checking', false)
              this.addForm[key].list.forEach(item=>{
                console.log(this.addForm[key].date)
                if(!item.ImgUrl){
                  imgValid[index] = false
                }else if((this.addForm[key].date && !this.addForm[key].date.join('')) || !this.addForm[key].date){
                  emptyDateType.push(key)
                }
                ImgUrl[index].push(item.ImgUrl)
                ImgJumpUrl[index].push(item.ImgJumpUrl)
              })
            })
            if(!imgValid.join('').includes('true')){//图都未上传
              this.$message({
                message: '请至少上传一处广告位图片',
                type: 'warning'
              });
              return
            }
            if(emptyDateType.length !== 0){//图传了未选择过期时间
              const str = emptyDateType.join(',').replace('left','左侧广告位').replace('right','右侧广告位').replace('exit','关闭广告位')
              this.$message({
                message: `请选择${str}的生效时间`,
                type: 'warning'
              });
              return
            }
            let list = []
            if (this.mulEdit) {
              list = this.muldata
            } else {
              this.addForm.Pid.forEach(item=>{
                list.push({
                  Pid: Number(item),
                  UserType: this.addForm.UserType
                })
              })
            }
            list.forEach(item=>{
              if(this.addForm.UserType === '' || this.addForm.UserType === 2){
                item.UserType = 0
                list.push({
                  Pid: item.Pid,
                  UserType: 1
                })
              }
            })
            this.loading = true
            let datas = [], type = ['LeftAds', 'RightAds', 'ExitAds']
            list.forEach((item,index)=> {
              datas[index] = {
                "product_type": 1,
                "data": {
                  Pid: item.Pid,
                  UserType: item.UserType,
                  LeftAds: {},
                  RightAds: {},
                  ExitAds: {}
                }
              }
              type.forEach((name, nameIndex)=>{
                datas[index].data[name] = {
                  Country: this.addForm[typeKey[nameIndex]].Country.join(','),
                  ImgUrl: ImgUrl[nameIndex].join(','),
                  ImgJumpUrl: ImgJumpUrl[nameIndex].join(','),
                  StartAt: this.addForm[typeKey[nameIndex]].date[0] ? new Date(this.addForm[typeKey[nameIndex]].date[0]).getTime() : '',
                  EndAt: this.addForm[typeKey[nameIndex]].date[1] ? new Date(this.addForm[typeKey[nameIndex]].date[1]).getTime() : '',
                  IsEnable: this.addForm[typeKey[nameIndex]].IsEnable || 0
                }
              })
            })
            addAds({
              product_type: 1,
              version: "v2.2",
              datas: datas
            }).then(res => {
              //  添加成功，刷新列表
              this.loadAdsInfo(this.page)
              this.dialogFormVisible = false
              this.loading = false
              this.$message({
                message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功', 
                type: 'success'
              });
            }).catch(err => {
              this.loading = false
              this.$message({
                message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
                type: 'warning'
              });
            })
          }
        })
      },
      beforeUpload(file) {   //导入
        const isLt1M = file.size / 1024 / 1024 < 1
        if (isLt1M) {
          return true
        }
        this.$message({
          message: '请不要上传大于1m的文件!',
          type: 'warning'
        })
        return false
      },

      handleSuccess({ results, header }) {  //导入内容
        if (header[0] != "ProductType" || header[1] != "Pid" || header[2] != "UserType" || header[3] != "country" || header[4] != "img_url" || header[5] != "img_jump_url" || header[6] != "start_at" || header[7] != "end_at" || header[8] != "is_enable")  {
          this.$message({
            message: '参数列表错误，请按对应格式进行导入【表头：【ProductType\tPid\tUserType\tcountry\timg_url\timg_jump_url\tstart_at\tend_at\tis_enable】',
            type: 'error'
          })
          return
        }

        let data = [];
        results.forEach((item,index)=> {
          data[index] = {
            "product_type": 1,
            "data": {
              Pid: item.Pid,
              UserType: item.UserType,
              Country: item.country,
              ImgUrl: item.img_url,
              ImgJumpUrl: item.img_jump_url,
              StartAt: item.start_at,
              EndAt: item.end_at,
              IsEnable: item.is_enable || 0
            }
          }
        })
        this.loading = true
        addAds({
          product_type: 1,
          version: "v2.2",
          datas: data
        }).then(response => {
          //  添加成功，刷新列表
          this.loadAdsInfo(this.page)
          this.loading = false
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
        }).catch(err => {
          this.loading = false
        })
      },
      //添加tab
      addTab(i, position){
        this.addForm[position].list.splice(i + 1, 0, {
          ImgUrl: '',
          ImgJumpUrl: '',
        })
      },
      //移除tab
      removeTab(i, position){
        this.addForm[position].list.splice(i, 1)
      },
      //上传图片
      async uploadImg(i, position){
        let _self = this
        let fileInput = _self.$refs['file_' + position + i][0], file = fileInput.files[0], fileExt = file.name.substring(file.name.lastIndexOf('.')).toLowerCase()
        _self.$set(_self.addForm[position], 'checking', true)
        if(!'.jpg,.jpeg,.png'.includes(fileExt)){
          _self.$set(_self.addForm[position].list[i], 'ImgExtNot', true)
          return
        }
        _self.$set(_self.addForm[position].list[i], 'ImgExtNot', false)
        if(position == 'left' || position == 'right'){
          const fileInfo = await _self.checkSize(file), wh = fileInfo.width / fileInfo.height
          console.log("width", fileInfo.width, "height", fileInfo.height)
          const imgWhLimit = {
            left: {
              width: 212,
              height: 280
            },
            right: {
              width: fileInfo.width,
              height: fileInfo.height
            }
          }
          const diff = wh - (imgWhLimit[position].width / imgWhLimit[position].height)
          if(!fileInfo.width || !fileInfo.height || ((diff > 0.01) || (diff < -0.01))){
            _self.$set(_self.addForm[position].list[i], 'ImgWhNot', true)
            return
          }
        }
        _self.$set(_self.addForm[position].list[i], 'ImgWhNot', false)
        _self.$set(_self.addForm[position], 'checking', false)
        let formData = new FormData()
        formData.append("img", file)
        fileInput.value = ''
        adsImg(formData).then(res => {
          _self.$set(_self.addForm[position].list[i], 'ImgUrl', location.origin + '/' + res.imgUrl)
        })
      },
      // 创建虚拟dom 并且返回对应的值
      async checkSize(files) {
        if (!files) return false

        return new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.onload = function (event) {
            const img = new Image()
            img.src = event.target.result

            img.onload = function () {
              resolve({
                width: img.width, // 尺寸宽 --- 分辨率
                height: img.height, // 尺寸高
              })
            }

            img.onerror = function () {
              reject(new Error('Failed to load image'))
            }
          }

          reader.onerror = function () {
            reject(new Error('Failed to read file'))
          }

          reader.readAsDataURL(files)
        })
      },
      //切换状态
      changeStatus(row){
        let type = ['LeftAds', 'RightAds', 'ExitAds'], data = {Pid: row.Pid, UserType: row.UserType}
        type.forEach(name=>{
          if(row.position_type === name){
            data[name] = {
              ImgUrl: row.img_url || '',
              ImgJumpUrl: row.img_jump_url || '',
              StartAt: row.start_at,
              EndAt: row.end_at,
              IsEnable: row.is_enable,
              Country: row.country
            }
          }else{
            data[name] = {
              ImgUrl: row[name].img_url || '',
              ImgJumpUrl: row[name].img_jump_url || '',
              StartAt: row[name].start_at,
              EndAt: row[name].end_at,
              IsEnable: row[name].is_enable,
              Country: row[name].country
            }
          }
        })
        addAds({
          product_type: 1,
          version: "v2.2",
          datas: [{
            "product_type": 1,
            "data": data
          }]
        }).then(()=>{
          row[row.position_type].is_enable = row.is_enable
        })
      },
      //返回地区名称
      resultCountry(v){
        let s = ''
        v.split(',').forEach(item=>{
          this.optionsCountry.forEach(item2=>{
            if(item === item2.value){
              s += item2.label + ' - ' + item2.value + ','
            }
          })
        })
        return s.substring(0, s.lastIndexOf(',')) || '全部地区'
      },
      //返回时间
      resultDateTime(v){
        return v ? formatDate(new Date(v), 'yyyy-MM-dd hh:mm:ss') : '-'
      }
    }
  }
</script>

<style scoped lang="scss">

.btn {
  display: flex;
}

.list-table {
  margin-bottom: 10px;
}

::v-deep {
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell{
    background: none;
  }
  .position{
    border: 1px solid #ddd;
    padding: 20px 20px 0;
    display: inline-block;
    margin-bottom: 20px;
    width: 49%;
    margin-right: 2%;
    vertical-align: top;
    >p{
      font-weight: bold;
      margin-bottom: 10px;
      color: green;
    }
  }
  .position:nth-child(even){
    margin-right: 0;
  }
}

</style>
