<template>
  <div class="nps-recoverit-contarner">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid"></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果:</span>
        <div class="btn">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="onMulEditNps"
            :style="{ display: mulSel }"
          >批量修改</el-button>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="onHandleAdd"
          >添加</el-button>
          <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/></div>
      </div>
      <!--数据列表-->
      <el-table
        :data="data.results"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          align="center"
          width="40">
        </el-table-column>
        <el-table-column
          prop="Pid"
          label="产品PID"
          align="center"
          width="100"
          sortable
        >
        </el-table-column>

        <el-table-column
          prop="nps.is_purchased"
          align="center"
          label="is_purchased">
          <template slot-scope="scope">
            <span v-if="scope.row.nps.is_purchased==0">/</span>
            <span v-if="scope.row.nps.is_purchased==1">/</span>
            <span v-if="scope.row.nps.is_purchased==2">false</span>
            <span v-if="scope.row.nps.is_purchased==3">true</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="nps.is_find_file"
          align="center"
          label="is_find_file">
          <template slot-scope="scope">
            <span v-if="scope.row.nps.is_find_file==0">/</span>
            <span v-if="scope.row.nps.is_find_file==1">/</span>
            <span v-if="scope.row.nps.is_find_file==2">false</span>
            <span v-if="scope.row.nps.is_find_file==3">true</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="nps.is_recover_failed"
          align="center"
          label="is_recover_failed">
          <template slot-scope="scope">
            <span v-if="scope.row.nps.is_recover_failed==0">/</span>
            <span v-if="scope.row.nps.is_recover_failed==1">/</span>
            <span v-if="scope.row.nps.is_recover_failed==2">false</span>
            <span v-if="scope.row.nps.is_recover_failed==3">true</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="nps.is_click_recover"
          align="center"
          label="is_click_recover">
          <template slot-scope="scope">
            <span v-if="scope.row.nps.is_click_recover==0">/</span>
            <span v-if="scope.row.nps.is_click_recover==1">/</span>
            <span v-if="scope.row.nps.is_click_recover==2">false</span>
            <span v-if="scope.row.nps.is_click_recover==3">true</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="nps.is_crash"
          align="center"
          label="is_crash">
          <template slot-scope="scope">
            <span v-if="scope.row.nps.is_crash==0">/</span>
            <span v-if="scope.row.nps.is_crash==1">/</span>
            <span v-if="scope.row.nps.is_crash==2">false</span>
            <span v-if="scope.row.nps.is_crash==3">true</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nps.is_rate"
          align="center"
          label="is_rate">
          <template slot-scope="scope">
            <span v-if="scope.row.nps.is_rate==0">/</span>
            <span v-if="scope.row.nps.is_rate==1">/</span>
            <span v-if="scope.row.nps.is_rate==2">false</span>
            <span v-if="scope.row.nps.is_rate==3">true</span>
          </template>
        </el-table-column>
        <el-table-column width="100"
          prop="create_by"
          align="center"
          :formatter="formatterEmpty"
          label="创建者">
        </el-table-column>
        <el-table-column width="100"
          prop="update_by"
          align="center"
          :formatter="formatterEmpty"
          label="更新人">
        </el-table-column>
        <el-table-column
          prop="update_time"
          align="center"
          :formatter="formatterDateTime"
          width="140"
          label="更新时间">
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditUserRate(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteUserRate(scope.row.Pid)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="产品PID: " prop="newPid">
          <el-input v-if="is_add" :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入用户PID" style="width: 220px"/>
            <el-tag class="pid-tag" v-else
              v-for="tag in muldata"
              :key="tag.Pid"
              type="danger"
            >
              {{tag.Pid}}
            </el-tag>
        </el-form-item>

        <el-form-item label="NPS: " class="nps-mode">
          <el-checkbox v-model="addForm.purchased.isCheck">is_purchased</el-checkbox>
          <el-form-item>
            <el-radio :disabled="!addForm.purchased.isCheck" :label="1" v-model="addForm.purchased.selStat">true</el-radio>
            <el-radio :disabled="!addForm.purchased.isCheck"  :label="0" v-model="addForm.purchased.selStat">false</el-radio>
          </el-form-item>

          <el-checkbox v-model="addForm.findFile.isCheck">is_find_file</el-checkbox>
          <el-form-item>
            <el-radio :disabled="!addForm.findFile.isCheck" :label="1" v-model="addForm.findFile.selStat">true</el-radio>
            <el-radio :disabled="!addForm.findFile.isCheck"  :label="0" v-model="addForm.findFile.selStat">false</el-radio>
          </el-form-item>

          <el-checkbox v-model="addForm.recoverFailed.isCheck">is_recover_failed</el-checkbox>
          <el-form-item>
            <el-radio :disabled="!addForm.recoverFailed.isCheck" :label="1" v-model="addForm.recoverFailed.selStat">true</el-radio>
            <el-radio :disabled="!addForm.recoverFailed.isCheck"  :label="0" v-model="addForm.recoverFailed.selStat">false</el-radio>
          </el-form-item>

          <el-checkbox v-model="addForm.clickRecover.isCheck">is_click_recover</el-checkbox>
          <el-form-item>
            <el-radio :disabled="!addForm.clickRecover.isCheck" :label="1" v-model="addForm.clickRecover.selStat">true</el-radio>
            <el-radio :disabled="!addForm.clickRecover.isCheck"  :label="0" v-model="addForm.clickRecover.selStat">false</el-radio>
          </el-form-item>

          <el-checkbox v-model="addForm.crash.isCheck">is_crash</el-checkbox>
          <el-form-item>
            <el-radio :disabled="!addForm.crash.isCheck" :label="1" v-model="addForm.crash.selStat">true</el-radio>
            <el-radio :disabled="!addForm.crash.isCheck"  :label="0" v-model="addForm.crash.selStat">false</el-radio>
          </el-form-item>

          <el-checkbox v-model="addForm.rate.isCheck">is_rate</el-checkbox>
          <el-form-item>
            <el-radio :disabled="!addForm.rate.isCheck" :label="1" v-model="addForm.rate.selStat">true</el-radio>
            <el-radio :disabled="!addForm.rate.isCheck"  :label="0" v-model="addForm.rate.selStat">false</el-radio>
          </el-form-item>
        </el-form-item>
      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddUserRate" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {addUserRate, delNps, getUserRates} from "@/api/rate";
  import UploadExcelComp from "@/components/common/UploadExcel";
  import {formatDate} from "@/utils/date"
  export default {
    name: "Recoverit.V2",
    components: {
      UploadExcelComp
    },
    data() {
      return {
        form: {
          name: ''
        },
        title: "",     // 弹出层标题
        pid: null,     // 用户PID
        loading: true, // 表单数据加载中
        data: [],      // 用户评论列表
        page: 1,       // 当前页码
        pageSize: 20,  // 每页大小
        dialogFormVisible: false, // 控制对话框的显示隐藏
        operType: false, // 操作类型
        is_add:  true,
        mulEdit: false,
        mulSel: 'none', // 多选按钮显示/隐藏样式
        muldata: [],
        // 添加用户信息
        addForm:{
          newPid: null, // 产品PID
          // NPS配置
          isPurchased: 0,     // 是否购买 0: 未激活， 1：未激活已购买，2：激活未购买，3：激活已购买
          purchased: {
            isCheck: true,
            selStat: 1,
          },
          isFindFile: 0,      // 是否发现文件 0: 未激活， 1：未激活发现文件，2：激活未发现文件，3：激活已发现文件
          findFile: {
            isCheck: true,
            selStat: 1,
          },
          isRecoverFailed: 0, // 是否修复失败 0: 未激活， 1：未激活修复失败，2：激活未修复失败，3：激活已修复失败
          recoverFailed: {
            isCheck: true,
            selStat: 1,
          },
          isClickRecover: 0,  // 是否点击恢复 0: 未激活， 1：未激活点击恢复，2：激活未点击恢复，3：激活已点击恢复
          clickRecover: {
            isCheck: true,
            selStat: 1,
          },
          IsCrash: 0,          // 是否崩溃 0: 未激活， 1：未激活崩溃，2：激活未崩溃，3：激活已崩溃
          crash: {
            isCheck: true,
            selStat: 1,
          },
          isRate: 0,          // 是否评分 0: 未激活， 1：未激活评分，2：激活未评分，3：激活已评分
          rate: {
            isCheck: true,
            selStat: 1,
          },
        },
        addFormRules: {
          newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
        }
      }
    },
    created() {
      this.loadNpsInfo()
    },
    // 计算属性
    computed: {
    },
    methods: {
      // 表单重置
      reset() {
        this.addForm = {
          newPid: null, // 产品PID
          // NPS配置
          isPurchased: 0,     // 是否购买 0: 未激活， 1：未激活已购买，2：激活未购买，3：激活已购买
          purchased: {
            isCheck: true,
            selStat: 1,
          },
          isFindFile: 0,      // 是否发现文件 0: 未激活， 1：未激活发现文件，2：激活未发现文件，3：激活已发现文件
          findFile: {
            isCheck: true,
            selStat: 1,
          },
          isRecoverFailed: 0, // 是否修复失败 0: 未激活， 1：未激活修复失败，2：激活未修复失败，3：激活已修复失败
          recoverFailed: {
            isCheck: true,
            selStat: 1,
          },
          isClickRecover: 0,  // 是否点击恢复 0: 未激活， 1：未激活点击恢复，2：激活未点击恢复，3：激活已点击恢复
          clickRecover: {
            isCheck: true,
            selStat: 1,
          },
          IsCrash: 0,          // 是否崩溃 0: 未激活， 1：未激活崩溃，2：激活未崩溃，3：激活已崩溃
          crash: {
            isCheck: true,
            selStat: 1,
          },
          isRate: 0,          // 是否评分 0: 未激活， 1：未激活评分，2：激活未评分，3：激活已评分
          rate: {
            isCheck: true,
            selStat: 1,
          },
        }
      },
      getActivateStat(val) {
        if (val === 0 ) {
          return {'isCheck': false, "selStat": 0}
        } else if (val === 1) {
          return {'isCheck': false, "selStat": 1}
        } else if (val === 2) {
          return {'isCheck': true, "selStat": 0}
        } else if (val === 3) {
          return {'isCheck': true, "selStat": 1}
        }
      },
      loadNpsInfo(page = 1) {
        this.loading = true
        getUserRates({
          "product_type": 0,
          page,
          "count": this.pageSize,
          "version": "v2.0",
          "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
        }).then(res => {
          this.data = res.data
          //  关闭加载中
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      handleSelectionChange(val) {
        this.mulEdit = true
        this.mulSel = val.length > 0 ? '' : 'none'
        this.muldata = val
      },
      /**
       * 查询
       */
      onSearch() {
        this.loadNpsInfo()
      },
      /**
       *  分页按钮响应交互
       */
      onCurrentChange(page) {
        this.loadNpsInfo(page)
      },
      /**
       *  新增用户NPS信息
       */
      onHandleAdd(str) {
        this.reset()
        this.operType = false
        this.dialogFormVisible = true
        this.is_add = true
        this.mulEdit = false
        this.title = "添加用户NPS信息"
      },
      /**
       * 删除用户NPS信息
       */
      onDeleteUserRate(pid) {
        this.$confirm('确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delNps({
            "version": "v2.0",
            "product_type": 0,
            "pid": pid
          }).then(res => {
            //  删除成功
            this.loadNpsInfo(this.page)
          })
        }).catch(() => {});
      },
      /**
       *  修改用户NPS信息
       */
      onEditUserRate(data) {
        this.reset()
        this.operType = true
        this.dialogFormVisible = true
        this.is_add = true
        this.mulEdit = false

        this.addForm.newPid = data.Pid
        this.addForm.isPurchased = data.nps.is_purchased
        var info = this.getActivateStat(data.nps.is_purchased)
        this.addForm.purchased.isCheck = info['isCheck']
        this.addForm.purchased.selStat = info['selStat']

        this.addForm.isFindFile = data.nps.is_find_file
        var info = this.getActivateStat(data.nps.is_find_file)
        this.addForm.findFile.isCheck = info['isCheck']
        this.addForm.findFile.selStat = info['selStat']

        this.addForm.isRecoverFailed = data.nps.is_recover_failed
        var info = this.getActivateStat(this.addForm.isRecoverFailed)
        this.addForm.recoverFailed.isCheck = info['isCheck']
        this.addForm.recoverFailed.selStat = info['selStat']

        this.addForm.isClickRecover = data.nps.is_click_recover
        var info = this.getActivateStat(this.addForm.isClickRecover)
        this.addForm.clickRecover.isCheck = info['isCheck']
        this.addForm.clickRecover.selStat = info['selStat']

        this.addForm.isCrash = data.nps.is_crash
        var info = this.getActivateStat(this.addForm.isCrash)
        this.addForm.crash.isCheck = info['isCheck']
        this.addForm.crash.selStat = info['selStat']

        this.addForm.isRate = data.nps.is_rate
        var info = this.getActivateStat(this.addForm.isRate)
        this.addForm.rate.isCheck = info['isCheck']
        this.addForm.rate.selStat = info['selStat']
        this.title = "修改用户NPS信息"
      },
      onMulEditNps() {
        this.reset()
        this.operType = true
        this.dialogFormVisible = true
        this.is_add = false

        if (this.muldata.length != 0) {
          this.addForm.newPid = this.muldata[0].Pid
          this.addForm.isPurchased = this.muldata[0].nps.is_purchased
          var info = this.getActivateStat(this.addForm.isPurchased)
          this.addForm.purchased.isCheck = info['isCheck']
          this.addForm.purchased.selStat = info['selStat']

          this.addForm.isFindFile = this.muldata[0].nps.is_find_file
          var info = this.getActivateStat(this.addForm.isFindFile)
          this.addForm.findFile.isCheck = info['isCheck']
          this.addForm.findFile.selStat = info['selStat']

          this.addForm.isRecoverFailed = this.muldata[0].nps.is_recover_failed
          var info = this.getActivateStat(this.addForm.isRecoverFailed)
          this.addForm.recoverFailed.isCheck = info['isCheck']
          this.addForm.recoverFailed.selStat = info['selStat']

          this.addForm.isClickRecover = this.muldata[0].nps.is_click_recover
          var info = this.getActivateStat(this.addForm.isClickRecover)
          this.addForm.clickRecover.isCheck = info['isCheck']
          this.addForm.clickRecover.selStat = info['selStat']

          this.addForm.isCrash = this.muldata[0].nps.is_crash
          var info = this.getActivateStat(this.addForm.isCrash)
          this.addForm.crash.isCheck = info['isCheck']
          this.addForm.crash.selStat = info['selStat']

          this.addForm.isRate = this.muldata[0].nps.is_rate
          var info = this.getActivateStat(this.addForm.isRate)
          this.addForm.rate.isCheck = info['isCheck']
          this.addForm.rate.selStat = info['selStat']
        }
        this.title = "批量修改用户NPS信息"
      },
      /**
       * 添加/修改用户Nps信息
       */
      onAddUserRate() {
        this.$refs["addFormRef"].validate(valid => {
          if (valid) {
            this.loading = true
            var data = []
            if (this.mulEdit) {
              data = this.muldata
            } else {
              data[0] = {"Pid":  parseInt(this.addForm.newPid)}
            }

            var res = new Array()
            data.forEach((item,index,data)=> {
              res[index] = {
                "product_type": 0,
                "data": {
                  "Pid": data[index].Pid,
                  "nps": {
                    "is_purchased": (this.addForm.purchased.isCheck ? (this.addForm.purchased.selStat === 0 ? 2 : 3) : (this.addForm.purchased.selStat === 0 ? 0 : 1)),
                    "is_find_file": (this.addForm.findFile.isCheck ? (this.addForm.findFile.selStat === 0 ? 2 : 3) : (this.addForm.findFile.selStat === 0 ? 0 : 1)),
                    "is_recover_failed": (this.addForm.recoverFailed.isCheck ? (this.addForm.recoverFailed.selStat === 0 ? 2 : 3) : (this.addForm.recoverFailed.selStat === 0 ? 0 : 1)),
                    "is_click_recover": (this.addForm.clickRecover.isCheck ? (this.addForm.clickRecover.selStat === 0 ? 2 : 3) : (this.addForm.clickRecover.selStat === 0 ? 0 : 1)),
                    "is_crash": (this.addForm.crash.isCheck ? (this.addForm.crash.selStat === 0 ? 2 : 3) : (this.addForm.crash.selStat === 0 ? 0 : 1)),
                    "is_rate": (this.addForm.rate.isCheck ? (this.addForm.rate.selStat === 0 ? 2 : 3) : (this.addForm.rate.selStat === 0 ? 0 : 1)),
                  }
                }
              }
            })

            addUserRate({
              "version": "v2.0",
              "datas": res
            }).then(res => {
              //  添加成功，刷新列表
              this.loadNpsInfo(this.page)
              this.dialogFormVisible = false
              this.loading = false
              this.$message({
                message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功',
                type: 'success'
              });
            }).catch(err => {
              this.loading = false
              this.$message({
                message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
                type: 'warning'
              });
            })
          }
        })
      },
      beforeUpload(file) {   //导入
        const isLt1M = file.size / 1024 / 1024 < 1
        if (isLt1M) {
          return true
        }
        this.$message({
          message: '请不要上传大于1m的文件!',
          type: 'warning'
        })
        return false
      },

      handleSuccess({ results, header }) {  //导入内容
        if (header[0] != "Pid" || header[1] != "is_purchased" || header[2] != "is_find_file" || header[3] != "is_recover_failed" || header[4] != "is_click_recover" || header[5] != "is_crash")  {
          this.$message({
            message: '参数列表错误，请按对应格式进行导入【表头：【Pid\tis_purchased\tis_find_file\tis_recover_failed\tis_click_recover\tis_crash】',
            type: 'error'
          })
          return
        }

        var res = new Array();
        results.forEach((item,index,results)=> {
          res[index] = {
            "product_type": 0,
            "data": {
              "Pid": results[index].Pid,
              "nps": {
                "is_purchased": results[index].is_purchased,
                "is_find_file": results[index].is_find_file,
                "is_recover_failed": results[index].is_recover_failed,
                "is_click_recover": results[index].is_click_recover,
                "is_crash": results[index].is_crash,
                "is_rate": 0,
              },
            }
          }
        })
        var params = {
          "version": "v2.0",
          "datas": res
        }
        this.loading = true
        addUserRate(params).then(response => {
          //  添加成功，刷新列表
          this.loadNpsInfo(this.page)
          this.loading = false
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
        }).catch(err => {
          this.loading = false
        })
      },
      //为空转换
      formatterEmpty(row, column, v){
        if (v || (v === 0)) return v
        return "-"
      },
      //时间转换
      formatterDateTime(row, column, v){
        if (!v) return "-"
        if(String(v).length === 10) v = v * 1000
        let date = new Date(v)
        return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
      }  
    }
  }
</script>

<style scoped>

.btn {
  display: flex;
}
.pid-tag{
  margin-right: 10px;
}

.list-table {
  margin-bottom: 10px;
}

.nps-mode .el-input{
  width: 120px;
}

</style>
