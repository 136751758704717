/**
 * 产品配置API请求
 * Body 参数使用 data 设置
 * Query 参数使用 params 设置
 * Headers 参数使用 headers 设置
 */

import request  from "@/utils/request";

export const getProductCfg = (data) => {
  return request({
    url:'/dbas/v2/product/list',
    method: 'POST',
    data: data
  })
}

export const addProductCfg = (data) => {
  return request({
    url:'dbas/v2/product',
    method: 'POST',
    data: data
  })
}

export const delProductCfg = (pid) => {
  return request({
    // 路径参数需要在url中传递
    // 接口API路径中有 :xxx 格式字段，则需要传递路径参数
    method: 'DELETE',
    url:'/dbas/v2/product/' + pid,
  })
}

export const delAppCommentsInfo = (pid, title) => {
  return request({
    // 路径参数需要在url中传递
    // 接口API路径中有 :xxx 格式字段，则需要传递路径参数
    method: 'DELETE',
    url:`/dbas/v2/app_comments/${pid}/${title}`
  })
}

//获取产品权限限制配置
export const getRepairitLicense = ({page, count}) => {
  return request({
    url:'/mrs/v1/get_repairit_license_cfg',
    method: 'GET',
    params: {
      version: 'v2.0',
      page,
      count
    }
  })
}

//设置产品权限限制配置
export const setRepairitLicense = (data) => {
  return request({
    url:'/mrs/v1/repairit_license',
    method: 'POST',
    params: {
      version: 'v2.0'
    },
    data: data
  })
}

//删除产品权限限制配置
export const delLicense = ({version, file_type, license}) => {
  return request({
    // 路径参数需要在url中传递
    // 接口API路径中有 :xxx 格式字段，则需要传递路径参数
    method: 'DELETE',
    params: {
      version
    },
    url:`/mrs/v1/repairit_license/${file_type}/${license}`
  })
}

