<template>
  <div class="nps-recoverit-contarner">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid"></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果:</span>
        <div class="btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
        <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/></div>
      </div>
      <!--数据列表-->
      <el-table
        :data="data.results"
        :span-method="objectSpanMethod"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        v-loading="loading"
        size="mini"
      >
        <el-table-column
          prop="Pid"
          label="产品PID"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="UserType"
          align="center"
          label="账户类型">
          <template slot-scope="scope">
            {{scope.row.UserType === 0 ? "试用" : "付费" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="popup.HasScan"
          align="center"
          label="开始扫描弹窗">
          <template slot-scope="scope">
            {{ scope.row.popup.HasScan === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="popup.HasScanComplete"
          label="扫描完成弹窗"
          align="center"
          width="130">
          <template slot-scope="scope">
            {{ scope.row.popup.HasScanComplete === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="popup.HasFindFiles"
          align="center"
          label="找到文件弹窗">
          <template slot-scope="scope">
            {{ scope.row.popup.HasFindFiles === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="popup.HasRecover"
          align="center"
          label="恢复过文件弹窗"
          width="130">
          <template slot-scope="scope">
            {{ scope.row.popup.HasRecover === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="popup.HasScanComplete"
          label="恢复成功弹窗"
          width="130"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.popup.HasScanComplete === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="nps.NpsMode"
          align="center"
          label="NpsMode">
          <template slot-scope="scope">
            {{ scope.row.nps.NpsMode === 0 ? "false" : "true" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="nps.DayMode"
          align="center"
          label="DayMode">
        </el-table-column>
        <el-table-column
          prop="nps.SumMode"
          align="center"
          label="SumMode">
        </el-table-column>
        <el-table-column
          prop="rfs.Rating"
          align="center"
          label="Rating">
        </el-table-column>
        <el-table-column width="100"
          prop="create_by"
          align="center"
          :formatter="formatterEmpty"
          label="创建人">
        </el-table-column>  
        <el-table-column width="100"
          prop="update_by"
          align="center"
          :formatter="formatterEmpty"
          label="更新人">
        </el-table-column>
        <el-table-column
          prop="update_time"
          align="center"
          :formatter="formatterDateTime"
          width="140"
          label="更新时间">
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditUserRate(scope.row)"
              size="mini"
            ></el-button>
            <el-button
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteUserRate(scope.row.Pid, scope.row.UserType)"
              size="mini"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="产品PID: " prop="newPid">
          <el-input :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入用户PID" style="width: 220px"/>
        </el-form-item>

        <el-form-item label="账户类型: ">
          <el-radio-group v-model="addForm.userType">
            <el-radio :disabled="operType" :label="0">试用账户</el-radio>
            <el-radio :disabled="operType" :label="1">付费账户</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="Rating: " prop="rating">
          <el-input-number :min="1" v-model="addForm.rating" placeholder="请输入Rating"/>
        </el-form-item>

        <el-form-item label="NPS: " class="nps-mode">
          <el-radio-group v-model="addForm.npsMode">
            <el-form-item  prop="dayMode">
              <el-radio :label="0" >DayMode <el-input-number :min="1" v-model="addForm.dayMode" controls-position="right" placeholder="days" class="el-input" /></el-radio>
            </el-form-item>
            <el-form-item  prop="sumMode" style="margin-top: 20px">
              <el-radio :label="1" >SumMode<el-input-number  :min="1" v-model="addForm.sumMode" controls-position="right" placeholder="days" class="el-input" /></el-radio>
            </el-form-item>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="弹窗配置: ">
          <el-checkbox v-model="addForm.hasScan">HasScan(开始扫描)</el-checkbox>
          <el-checkbox v-model="addForm.hasScanComplete">HasScanComplete(扫描完成)</el-checkbox>
          <el-checkbox v-model="addForm.hasFindFiles">HasFindFiles(找到文件)</el-checkbox>
          <el-checkbox v-model="addForm.hasRecover">HasRecover(恢复过文件)</el-checkbox>
          <el-checkbox v-model="addForm.recoverSuccess">RecoverSuccess(恢复成功)</el-checkbox>
        </el-form-item>

      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddUserRate" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>

  import {addUserRate, delUserRate, getUserRates} from "@/api/rate";
  import UploadExcelComp from "@/components/common/UploadExcel";
  import {formatDate} from "@/utils/date"

  export default {
  name: "NpsRecoverit",
  components: {
    UploadExcelComp
  },
  data() {
    return {
      form: {
        name: ''
      },
      title: "",     // 弹出层标题
      pid: null,     // 用户PID
      loading: true, // 表单数据加载中
      data: [],      // 用户评论列表
      page: 1,       // 当前页码
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      // 添加用户信息
      addForm:{
        newPid: null, // 产品PID
        userType: 1,  // 用户类型 0：试用类型 1：付费类型
        // Recoverit 弹窗配置
        hasScan: true,         // 弹窗 有扫描
        hasScanComplete: true, // 弹窗 扫描完成
        hasFindFiles: true,    // 弹窗 找到文件
        hasRecover: true,      // 弹窗 已修复
        recoverSuccess: true,  // 弹窗 恢复成功
        // RecoveritFeeSetting配置
        rating: 10,   //
        // NPS配置
        npsMode: 0,   // nps弹窗模式 0：DayMode 1：SumMode
        dayMode: 1,   // 单日最多弹N次
        sumMode: 1    // 在软件生命周期内最多弹N次
      },
      addFormRules: {
        newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
        rating: [{required: true, trigger: 'blur', message: "rating不能为空"}],
        dayMode:[{required: true, trigger: 'blur', message: "DayMode不能为空"}],
        sumMode:[{required: true, trigger: 'blur', message: "SumMode不能为空"}]
      }
    }
  },
  created() {
    this.loadNpsInfo()
  },
  methods: {
    // 表单重置
    reset() {
      this.addForm = {
        newPid: null, // 产品PID
        userType: 1,  // 用户类型 0：试用类型 1：付费类型
        // Recoverit 弹窗配置
        hasScan: true,         // 弹窗 有扫描
        hasScanComplete: true, // 弹窗 扫描完成
        hasFindFiles: true,    // 弹窗 找到文件
        hasRecover: true,      // 弹窗 已修复
        recoverSuccess: true,  // 弹窗 恢复成功
        // RecoveritFeeSetting配置
        rating: 10,   //
        // NPS配置
        npsMode: 0,   // nps弹窗模式 0：DayMode 1：SumMode
        dayMode: 1,   // 单日最多弹N次
        sumMode: 1    // 在软件生命周期内最多弹N次
      }
    },
    loadNpsInfo(page = 1) {
      this.loading = true
      getUserRates({
        "product_type": 0,
        page,
        "count": this.pageSize,
        "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
      }).then(res => {
        this.data = res.data
        this.getRowSpan()
        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    getRowSpan() {
      this.rowSpanArr = [];
      this.data.results.forEach((item, index) => {
        if (index == 0) {
          this.rowSpanArr.push(1);
          this.position = 0;
        } else {
          if (this.data.results[index].Pid == this.data.results[index - 1].Pid) {
            this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
            this.rowSpanArr.push(0);
            this.data.results[index].Pid = this.data.results[index - 1].Pid;
          } else {
            this.rowSpanArr.push(1);
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 只合并区域位置
      if (columnIndex === 0) {
        const _row = this.rowSpanArr[rowIndex];
        return {
          rowspan: _row, //行
          colspan: 1 //列
        };
      }
    },
    /**
     * 查询
     */
    onSearch() {
      this.loadNpsInfo()
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadNpsInfo(page)
    },
    /**
     *  新增用户评价信息
     */
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加用户评分信息"
    },
    /**
     * 删除用户评价信息
     */
    onDeleteUserRate(pid, userType) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delUserRate(0, pid, userType).then(res => {
          //  删除成功
          this.loadNpsInfo(this.page)
        })
      }).catch(() => {});
    },
    /**
     *  修改用户评价信息
     */
    onEditUserRate(data) {
      this.reset()
      this.operType = true
      this.dialogFormVisible = true
      this.addForm.newPid = data.Pid
      this.addForm.userType = data.UserType
      this.addForm.rating = data.rfs.Rating
      this.addForm.npsMode = data.nps.NpsMode
      this.addForm.dayMode = data.nps.DayMode
      this.addForm.sumMode = data.nps.SumMode
      this.addForm.hasScan = data.popup.HasScan == 0 ? false : true
      this.addForm.hasScanComplete = data.popup.HasScanComplete == 0 ? false : true
      this.addForm.hasFindFiles = data.popup.HasFindFiles == 0 ? false : true
      this.addForm.hasRecover = data.popup.HasRecover == 0 ? false : true
      this.addForm.recoverSuccess = data.popup.RecoverSuccess == 0 ? false : true
      this.title = "修改用户评分信息"
    },
    /**
     * 添加用户Nps信息
     */
    onAddUserRate() {
      this.$refs["addFormRef"].validate(valid => {
        if (valid) {
          this.loading = true
          addUserRate({
            "product_type": 0, //this.newform.productType
            "data": {
              "Pid": parseInt(this.addForm.newPid),
              "UserType": this.addForm.userType,
              "rfs": {
                "Rating": this.addForm.rating,
              },
              "nps": {
                "NpsMode": this.addForm.npsMode,
                "DayMode": this.addForm.dayMode,
                "SumMode": this.addForm.sumMode,
              },
              "popup": {
                "HasScan": this.addForm.hasScan ? 1: 0,
                "HasScanComplete": this.addForm.hasScanComplete ? 1: 0,
                "HasFindFiles": this.addForm.hasFindFiles ? 1: 0,
                "HasRecover": this.addForm.hasRecover ? 1: 0,
                "RecoverSuccess": this.addForm.recoverSuccess ? 1: 0
              }
            }
          }).then(res => {
            //  添加成功，刷新列表
            this.loadNpsInfo(this.page)
            this.loading = false
            this.$message({
              message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功',
              type: 'success'
            });
          }).catch(err => {
            this.loading = false
            this.$message({
              message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
              type: 'warning'
            });
          })
        }
      })
    },
    beforeUpload(file) {   //导入
      var a = file
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: '请不要上传大于1m的文件!',
        type: 'warning'
      })
      return false
    },

    handleSuccess({ results, header }) {  //导入内容
      if (header[0] != "Pid" || header[1] != "UserType" || header[2] != "Rating" || header[3] != "NpsMode" || header[4] != "DayMode" || header[5] != "SumMode" || header[6] != "HasScan" || header[7] != "HasScanComplete" || header[8] != "HasFindFiles" || header[9] != "HasRecover" || header[10] != "RecoverSuccess")  {
        this.$message({
          message: '参数列表错误，请按对应格式进行导入【表头：【Pid\tUserType\tRating\tNpsMode\tDayMode\tSumMode\tHasScan\tHasScanComplete\tHasFindFiles\tHasRecover\tRecoverSuccess】',
          type: 'error'
        })
        return
      }

      var res = new Array();
      results.forEach((item,index,results)=> {
        res[index] = {
          "product_type": 0,
          "data": {
            "Pid": results[index].Pid,
            "UserType": results[index].UserType,
            "rfs": {
              "Rating": results[index].Rating,
            },
            "nps": {
              "NpsMode": results[index].NpsMode,
              "DayMode": results[index].DayMode,
              "SumMode": results[index].SumMode,
            },
            "popup": {
              "HasScan": results[index].HasScan,
              "HasScanComplete": results[index].HasScanComplete,
              "HasFindFiles": results[index].HasFindFiles,
              "HasRecover": results[index].HasRecover,
              "RecoverSuccess": results[index].RecoverSuccess
            }
          }
        }
      })
      var params = {
        "datas": res
      }
      this.loading = true
      addUserRate(params).then(response => {
        //  添加成功，刷新列表
        this.loadNpsInfo(this.page)
        this.loading = false
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
      }).catch(err => {
        this.loading = false
      })
    },
    //为空
    formatterEmpty(row, column, v){
      if (v || (v === 0)) return v
      return "-"
    },
    //时间转换
    formatterDateTime(row, column, v){
      if (!v) return "-"
      if(String(v).length === 10) v = v * 1000
      let date = new Date(v)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    } 
  }
}
</script>

<style scoped>

.btn {
  display: flex;
}

.list-table {
  margin-bottom: 10px;
}

.nps-mode .el-input{
  width: 120px;
}

</style>
