<template>
  <div class="feedback">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid"></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
      </div>
      <!--数据列表-->
      <el-table
        :data="data.results"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="pid"
          label="产品PID"
          width="100px"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="UserFeedback.HDnotFound"
          label="HDnotFound"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.DiskDisconnect"
          label="DiskDisconnect"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.FileScannedOut"
          label="FileScannedOut"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.AdvancedRepairFailed"
          label="AdvancedRepairFailed"
          align="center"
          width="180px"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.CreatUSBFailed"
          label="CreatUSBFailed"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.DrEngineCrash"
          label="DrEngineCrash"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.FalseAnitPricacyReport"
          label="FalseAnitPricacyReport"
          align="center"
          width="180px"
        >
        </el-table-column>

        <el-table-column
          prop="UserFeedback.RatingFeedback"
          label="RatingFeedback"
          align="center"
        >
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditFeedbackInfo(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteFeedbackInfo(scope.row.pid)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="400px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="PID: " prop="newPid">
          <el-input :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入PID" style="width: 220px" />
        </el-form-item>

        <el-form-item label="是否启用: ">
          <el-checkbox v-model="addForm.hDnotFound">HDnotFound</el-checkbox>
          <el-checkbox v-model="addForm.diskDisconnect">DiskDisconnect</el-checkbox>
          <el-checkbox v-model="addForm.fileScannedOut">FileScannedOut</el-checkbox>
          <el-checkbox v-model="addForm.filesRecoveryFailed">FilesRecoveryFailed</el-checkbox>
          <el-checkbox v-model="addForm.advancedRepairFailed">AdvancedRepairFailed</el-checkbox>
          <el-checkbox v-model="addForm.creatUSBFailed">CreatUSBFailed</el-checkbox>
          <el-checkbox v-model="addForm.drEngineCrash">DrEngineCrash</el-checkbox>
          <el-checkbox v-model="addForm.falseAnitPricacyReport">FalseAnitPricacyReport</el-checkbox>
          <el-checkbox v-model="addForm.ratingFeedback">RatingFeedback</el-checkbox>
        </el-form-item>

      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddFeedbackInfo" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getFeedbackInfo, addFeedbackInfo, delFeedbackInfo} from "@/api/feedback";

export default {
  name: "Feedback",
  data() {
    return {
      form: {
        name: ''
      },
      title: "",     // 弹出层标题
      pid: null,     // 用户PID
      loading: true, // 表单数据加载中
      data: [],      // 产品配置列表
      page: 1,       // 当前页码
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      // 添加用户反馈信息
      addForm:{
        newPid: null,
        hDnotFound: false,
        diskDisconnect: true,
        fileScannedOut: true,
        filesRecoveryFailed: true,
        advancedRepairFailed: true,
        creatUSBFailed: true,
        drEngineCrash: true,
        falseAnitPricacyReport: true,
        ratingFeedback: true,
      },
      addFormRules: {
        newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
      }
    }
  },
  created() {
    this.loadFeedbackInfo()
  },
  methods: {
    // 表单重置
    reset() {
      this.addForm = {
        newPid: null,
        hDnotFound: false,
        diskDisconnect: true,
        fileScannedOut: true,
        filesRecoveryFailed: true,
        advancedRepairFailed: true,
        creatUSBFailed: true,
        drEngineCrash: true,
        falseAnitPricacyReport: true,
        ratingFeedback: true,
      }
    },
    loadFeedbackInfo(page = 1) {
      this.loading = true
      getFeedbackInfo({
        page,
        "count": this.pageSize,
        "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
      }).then(res => {
        this.data = res.data
        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadProductCfgInfo(page)
    },
    /**
     *  根据条件查询
     */
    onSearch() {
      this.loadFeedbackInfo()
    },
    /**
     *  新增用户反馈信息
     */
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加用户反馈信息"
    },
    /**
     * 删除App评论信息
     */
    onDeleteFeedbackInfo(pid) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delFeedbackInfo(pid).then(res => {
          //  删除成功
          this.loadFeedbackInfo(this.page)
        })
      }).catch(() => {});
    },
    /**
     *  修改用户反馈信息
     */
    onEditFeedbackInfo(data) {
      this.reset()
      this.operType = true
      this.dialogFormVisible = true
      this.addForm.newPid = data.pid
      this.addForm.hDnotFound = data.UserFeedback.HDnotFound ? true : false
      this.addForm.diskDisconnect = data.UserFeedback.DiskDisconnect ? true : false
      this.addForm.fileScannedOut = data.UserFeedback.FileScannedOut ? true : false
      this.addForm.filesRecoveryFailed = data.UserFeedback.FilesRecoveryFailed ? true : false
      this.addForm.advancedRepairFailed = data.UserFeedback.AdvancedRepairFailed ? true : false
      this.addForm.creatUSBFailed = data.UserFeedback.CreatUSBFailed ? true : false
      this.addForm.drEngineCrash = data.UserFeedback.DrEngineCrash ? true : false
      this.addForm.falseAnitPricacyReport = data.UserFeedback.FalseAnitPricacyReport ? true : false
      this.addForm.ratingFeedback = data.UserFeedback.RatingFeedback ? true : false
      this.title = "修改用户反馈信息"
    },
    /**
     * 添加用户反馈信息
     */
    onAddFeedbackInfo() {
      this.$refs["addFormRef"].validate(valid => {
        if (valid) {
          this.loading = true
          addFeedbackInfo({
            "data": {
              "Pid": parseInt(this.addForm.newPid),
              "UserFeedback": {
                "HDnotFound": this.addForm.hDnotFound ? 1 : 0,
                "DiskDisconnect": this.addForm.diskDisconnect ? 1 : 0,
                "FileScannedOut": this.addForm.fileScannedOut ? 1 : 0,
                "FilesRecoveryFailed": this.addForm.filesRecoveryFailed ? 1 : 0,
                "AdvancedRepairFailed": this.addForm.advancedRepairFailed ? 1 : 0,
                "CreatUSBFailed": this.addForm.creatUSBFailed ? 1 : 0,
                "DrEngineCrash": this.addForm.drEngineCrash ? 1 : 0,
                "FalseAnitPricacyReport": this.addForm.falseAnitPricacyReport ? 1 : 0,
                "RatingFeedback": this.addForm.ratingFeedback ? 1 : 0,
              }
            }

          }).then(res => {
            //  添加成功，刷新列表
            this.loadFeedbackInfo(this.page)
            this.loading = false
            this.$message({
              message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功',
              type: 'success'
            });
          }).catch(err => {
            this.loading = false
            this.$message({
              message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
              type: 'warning'
            });
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.list-table {
  margin-bottom: 10px;
}
</style>
