<template>
  <div class="home-container">
    <el-card class="filter-card">
      <div slot="header">
        <i class="el-icon-message-solid"></i>
        <span> 版本更新公告：</span>
      </div>
      <el-carousel height="200px" direction="vertical" >

        <el-carousel-item v-for="item in version_info" :key="item.index">
          <h1>{{item.version}}</h1>
          <h3 class="medium">{{ item.value }}</h3>
        </el-carousel-item>
      </el-carousel>

      <el-collapse accordion>
        <el-collapse-item title="v2.1.8" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>新增Recoverit运营位配置</h3>
        </el-collapse-item>
        <el-collapse-item title="v2.1.7" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>新增Recoverit产品配置DiscountSwitch折扣开关</h3>
        </el-collapse-item>
        <el-collapse-item title="v2.1.6" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>新增产品recoverit配置LogSwitch日志开关</h3>
        </el-collapse-item>
        <el-collapse-item title="v2.1.5" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>1. 新增用户权限管理，分为：超级管理员，管理员，普通用户，只读用户</h3>
          <h3>2. 新增动态菜单路由菜单路由,根据不同的用户访问权限进行动态路由菜单的展示</h3>
          <h3>3. 新增不同权限下的请求策略，包括增、删、改、查下的权限配置</h3>
          <h3>4. 新增管理员针对权限下的角色密码重置机制</h3>
          <h3>5. 新增用户状态配置策略，管理员可对权限下的角色进行禁用，启用，激活</h3>
        </el-collapse-item>
        
        <el-collapse-item title="v2.0.4" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>修改repairit配置中的普通修复和高级修复成功率的范围最大值为150</h3>
        </el-collapse-item>   

        <el-collapse-item title="v2.0.3" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>修改聊天记录恢复配置中的NPS模式的基准值支持0</h3>
        </el-collapse-item>   

        <el-collapse-item title="v2.0.2" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>1. 更新网页标题</h3>
          <h3>2. 新增账号过期机制</h3>
        </el-collapse-item>

        <el-collapse-item title="v2.0.1" style="font-size: 14px; opacity: 0.75; margin-top: 10px; white-space: pre-wrap;">
          <h3>1. 整体页面UI设计布局</h3>
          <h3>2. 新增登录鉴权机制，个人用户设置[个人信息展示、基本资料的修改]</h3>
          <h3>3. 新增NPS[recoverit/recoverit_new/repairit/聊天记录恢复]、产品配置[recoverit/repairit/聊天记录恢复/ubackit/app]、用户反馈功能配置模块</h3>
          <h3>4. 新增各个模块的新增[批量新增] 查询、删除、修改[批量修改]、分页</h3>
        </el-collapse-item>

      </el-collapse>

    </el-card>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      version_info: [
        {
          version: "v2.1.8",
          value: "新增Recoverit运营位配置 "
        }, 
        {
          version: "v2.1.7",
          value: "新增Recoverit产品配置DiscountSwitch折扣开关"
        },       
        {
          version: "v2.1.6",
          value: "新增产品recoverit配置LogSwitch日志开关"
        },
        {
         version: "v2.1.5",
          value: "1. 新增用户权限管理，分为：超级管理员，管理员，普通用户，只读用户\n" + 
                 "2. 新增动态菜单路由菜单路由,根据不同的用户访问权限进行动态路由菜单的展示\n" + 
                 "3. 新增不同权限下的请求策略，包括增、删、改、查下的权限配置\n" + 
                 "4. 新增管理员针对权限下的角色密码重置机制\n" + 
                 "5. 新增用户状态配置策略，管理员可对权限下的角色进行禁用，启用，激活"
        },
      ]
    }
  },
  created() { }
}
</script>

<style scoped>
  .el-carousel__item h1,h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    margin-top: 10px;
    white-space: pre-wrap;
  }
</style>
