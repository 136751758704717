/**
 * 用户评价API请求
 * Body 参数使用 data 设置
 * Query 参数使用 params 设置
 * Headers 参数使用 headers 设置
 */

import request  from "@/utils/request";

export const getUserRates = (data) => {
  return request({
    url:'/dbas/v2/rate/list',
    method: 'POST',
    data: data
  })
}

export const delUserRate = (product_type, pid, user_type) => {
  return request({
    // 路径参数需要在url中传递
    // 接口API路径中有 :xxx 格式字段，则需要传递路径参数
    method: 'DELETE',
    url:`/dbas/v2/rate/${product_type}/${pid}/${user_type}`
  })
}

export const addUserRate = (data) => {
  return request({
    method: 'POST',
    url: '/dbas/v2/rate',
    data: data
  })
}

export const delNps = (data) => {
  return request({
    method: 'DELETE',
    url:'/dbas/v2/rate',
    data: data
  })
}
