<template>
  <div class="app">
    <el-card class="filter-card">
      <!-- 数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid"></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!-- 配置表单 -->
    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <div class="btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
        <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/></div>
      </div>

      <!--数据列表-->
      <el-table
        :data="data.results"
        :span-method="objectSpanMethod"
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="Pid"
          label="产品PID"
          width="100px"
        >
        </el-table-column>

        <el-table-column
          prop="AppType"
          label="服务类型"
          width="120px"
          align="center"
        >
        </el-table-column>

        <el-table-column
          label="启用状态"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.appComments[0].selStatus == 0" style="color:red">禁用</span>
            <span v-else>启用</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="appComments[0].title"
          label="标题"
          width="200px"
        >
        </el-table-column>

        <el-table-column
          prop="appComments[0].userName"
          label="用户名"
          width="120px"
        >
        </el-table-column>

        <el-table-column
          prop="appComments[0].rate"
          label="评分"
          width="60px"
          align="center"
          :formatter="formatterEmpty"
        >
        </el-table-column>

        <el-table-column
          prop="appComments[0].comments"
          :formatter="formatterEmpty"
          label="内容">
        </el-table-column>

        <el-table-column width="100"
          prop="create_by"
          align="center"
          :formatter="formatterEmpty"
          label="创建者">
        </el-table-column> 
        <el-table-column width="100"
          align="center"
          prop="update_by"
          :formatter="formatterEmpty"
          label="更新者">
        </el-table-column>

        <el-table-column
          prop="update_time"
          label="UpdateTime"
          :formatter="formatterDateTime"
          align="center"
          width="160px">
        </el-table-column>

        <el-table-column label="操作" :width="120" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditAppCommentsInfo(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteAppCommentsInfo(scope.row.Pid, scope.row.appComments[0].title)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="120px">
        <el-form-item label="PID: " prop="newPid">
          <el-input :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入PID"/>
        </el-form-item>

        <el-form-item label="评论是否启用: ">
        <el-checkbox v-model="addForm.selStatus">是否启用</el-checkbox>
        </el-form-item>

        <el-form-item label="ServiceType: " prop="appType">
          <el-input v-model="addForm.appType" placeholder="请输入评论服务类型"/>
        </el-form-item>

        <el-form-item label="Title: " prop="title">
          <el-input :disabled="operType" v-model="addForm.title" placeholder="请输入标题"></el-input>
        </el-form-item>

        <el-form-item label="NewTitle: " prop="newTitle">
          <el-input :disabled="!operType" v-model="addForm.newTitle" placeholder="请输入新标题"></el-input>
        </el-form-item>

        <el-form-item label="UserName: " prop="userName">
          <el-input v-model="addForm.userName" placeholder="请输入用户名称"/>
        </el-form-item>

        <el-form-item label="Rate: " prop="rate">
          <el-input v-model="addForm.rate" placeholder="请输入评分" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"/>
        </el-form-item>

        <el-form-item label="Comments: " prop="comments">
          <el-input type="textarea" autosize v-model="addForm.comments" placeholder="请输入评论内容"/>
        </el-form-item>

      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddAppCommentsInfo" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {addProductCfg, getProductCfg, delAppCommentsInfo} from "@/api/productcfg";
import UploadExcelComp from "@/components/common/UploadExcel";
import {formatDate} from "@/utils/date"

export default {
  name: "App",
  components: {
    UploadExcelComp
  },
  data() {
    return {
      form: {
        name: ''
      },
      title:"",     // 弹出层标题
      pid: null,     // 用户PID
      loading: true, // 表单数据加载中
      data: [],      // 云端媒体特征信息配置列表
      page: 1,       // 当前页码
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      // 添加App评论信息
      addForm:{
        newPid: null,
        appType: "",
        title: "",
        newTitle: "",
        userName: "",
        rate: 5,
        comments: "",
        selStatus: true,
      },
      addFormRules: {
        newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
        appType:[{required: true, trigger: 'blur', message: "ServiceType不能为空"}],
        title:[{required: true, trigger: 'blur', message: "Title不能为空"}],
        userName:[{required: true, trigger: 'blur', message: "UserName不能为空"}],
        rate:[{required: true, trigger: 'blur', message: "Rate不能为空"}],
        comments:[{required: true, trigger: 'blur', message: "Comments不能为空"}]
      }
    }
  },
  created() {
    this.loadAppCommentsInfo()
  },
  methods: {
    // 表单重置
    reset() {
      this.addForm = {
        newPid: null,
        appType: "",
        title: "",
        newTitle: "",
        userName: "",
        rate: 5,
        comments: "",
        selStatus: true
      }
    },
    loadAppCommentsInfo(page = 1) {
      this.loading = true
      getProductCfg({
        "product_type": 4,
        page,
        "count": this.pageSize,
        "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
      }).then(res => {
        this.data = res.data
        this.getRowSpan()
        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    getRowSpan() {
      this.rowSpanArr = [];
      this.data.results.forEach((item, index) => {
        if (index == 0) {
          this.rowSpanArr.push(1);
          this.position = 0;
        } else {
          if (this.data.results[index].Pid == this.data.results[index - 1].Pid) {
            this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
            this.rowSpanArr.push(0);
            this.data.results[index].Pid = this.data.results[index - 1].Pid;
          } else {
            this.rowSpanArr.push(1);
            this.position = index;
          }
        }
      });
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 只合并区域位置
      if (columnIndex === 0) {
        const _row = this.rowSpanArr[rowIndex];
        const _col = _row > 0 ? 1: 0
        return {
          rowspan: _row, //行
          colspan: _col //列
        };
      }
      if (columnIndex === 1) {
        const _row = this.rowSpanArr[rowIndex];
        const _col = _row > 0 ? 1: 0
        return {
          rowspan: _row, //行
          colspan: _col //列
        };
      }
    },
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加App评论信息"
    },
    /**
     *  根据条件查询
     */
    onSearch() {
      this.loadAppCommentsInfo()
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadAppCommentsInfo(page)
    },
    /**
     * 删除App评论信息
     */
    onDeleteAppCommentsInfo(pid, title) {
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAppCommentsInfo(pid, title).then(res => {
          //  删除成功
          this.loadAppCommentsInfo(this.page)
        })
      }).catch(() => {});
    },
    /**
     *  修改App评论信息
     */
    onEditAppCommentsInfo(data) {
      this.reset()
      this.operType = true
      this.dialogFormVisible = true
      this.addForm.newPid = data.Pid
      this.addForm.appType = data.AppType
      this.addForm.title = data.appComments[0].title
      this.addForm.newTitle = data.appComments[0].newTitle
      this.addForm.userName = data.appComments[0].userName
      this.addForm.rate = data.appComments[0].rate
      this.addForm.comments = data.appComments[0].comments
      this.addForm.selStatus = data.appComments[0].selStatus === 1 ? true: false
      this.title = "修改App评论信息"
    },
    /**
     * 添加App评论信息
     */
    onAddAppCommentsInfo() {
      this.$refs["addFormRef"].validate(valid => {
        if (valid) {
          this.loading = true
          addProductCfg({
            "product_type": 4,
            "data": {
              "Pid": parseInt(this.addForm.newPid),
              "AppType": this.addForm.appType,
              "appComments": {
                "title": this.addForm.title,
                "newTitle": this.addForm.newTitle,
                "userName": this.addForm.userName,
                "rate": parseInt(this.addForm.rate),
                "comments": this.addForm.comments,
                "selStatus": this.addForm.selStatus ? 1 : 0,
              }
            }

          }).then(res => {
            //  添加成功，刷新列表
            this.loadAppCommentsInfo(this.page)
            this.loading = false
            this.$message({
              message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功',
              type: 'success'
            });
            if (this.operType) {
              this.dialogFormVisible = false
            }
          }).catch(err => {
            this.loading = false
            this.$message({
              message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
              type: 'warning'
            });
          })
        }
      })
    },
    beforeUpload(file) {   //导入
      var a = file
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: '请不要上传大于1m的文件!',
        type: 'warning'
      })
      return false
    },
    handleSuccess({ results, header }) {  //导入内容
      console.log(header);
      if (header[0] != "Pid" || header[1] != "AppType" || header[2] != "title" || header[3] != "newTitle" || header[4] != "userName" || header[5] != "rate" || header[6] != "comments" || header[7] != "selStatus" )  {
        this.$message({
          message: '参数列表错误，请按对应格式进行导入【表头：Pid\tAppType\ttitle\tnewTitle\tuserName\trate\tcomments\tselStatus】',
          type: 'warning'
        })
        return
      }
      var res = new Array();
      results.forEach((item,index,results)=> {
        res[index] = {
          "product_type": 4,
          "data": {
            "Pid": results[index].Pid,
            "AppType": results[index].AppType,
            "appComments": {
              "title": results[index].title,
              "newTitle": results[index].newTitle,
              "userName": results[index].userName,
              "rate": results[index].rate,
              "comments": results[index].comments,
              "selStatus": results[index].selStatus,
            }
          }
        }
      })
      var params = {
        "datas": res
      }
      this.loading = true
      addProductCfg(params).then(response => {
        //  添加成功，刷新列表
        this.loadAppCommentsInfo(this.page)
        this.loading = false
        this.$message({
          type: 'success',
          message: '添加成功!'
        });
      })
    },
    //为空转换
    formatterEmpty(row, column, v){
      if (v || (v === 0)) return v
      return "-"
    },
    //时间转换
    formatterDateTime(row, column, v){
      if (!v) return "-"
      if(String(v).length === 10) v = v * 1000
      let date = new Date(v)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }   
  }
}
</script>

<style scoped>

.btn {
  display: flex;
}

.list-table {
  margin-bottom: 10px;
}
</style>
