/**
 * 产品配置API请求
 * Body 参数使用 data 设置
 * Query 参数使用 params 设置
 * Headers 参数使用 headers 设置
 */

import request  from "@/utils/request";

export const getCloudTraitInfo = (data) => {
  return request({
    url:'/cloudsvr/v2/cloud_trait/list',
    method: 'POST',
    data: data
  })
}

export const addCloudTraitInfo = (data) => {
  return request({
    url:'cloudsvr/v2/cloud_trait',
    method: 'POST',
    data: data
  })
}

export const delCloudTraitInfo = (data) => {
  return request({
    // 路径参数需要在url中传递
    // 接口API路径中有 :xxx 格式字段，则需要传递路径参数
    method: 'DELETE',
    url:'/cloudsvr/v2/cloud_trait',
    data: data
  })
}
