<template>
  <div class="licenseConfigure">
    <!-- <el-card class="filter-card">
      <div slot="header" class="clearfix">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>用户管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-card> -->

    <!-- 配置表单 -->
    <el-card class="manage-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <div class="btn">
          <el-button :disabled="user.role_type > 2"
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="onHandleAdd"
          >添加</el-button>
        </div>
      </div>
      <!--数据列表-->
      <el-table
        :data="data.results"
        :span-method="objectSpanMethod"
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      > 
        <el-table-column  width="70" align="center"
          prop="file_type"
          label="ID">
        </el-table-column>
        <el-table-column width="150"
          label="修复类型">
          <template slot-scope="scope">
            {{ scope.row.type_name || '-'}}
          </template>
        </el-table-column>
        <el-table-column width="100"
          prop="license"
          align="center"
          label="授权状态">
          <template slot-scope="scope">
            {{ scope.row.license == 1 ? '付费' : '免费' }}
          </template>
        </el-table-column>    
        <el-table-column
          prop="format"
          label="支持格式">
        </el-table-column>
        <el-table-column width="100"
          prop="format_files"
          align="center"
          label="支持格式数量">
        </el-table-column>      
        <el-table-column width="120"
          prop="upload_limit"
          align="center"
          label="大小限制（MB）">
          <template slot-scope="scope">
            {{ scope.row.upload_limit || '不限' }}
          </template>
        </el-table-column>    
        <el-table-column width="120"
          prop="repair_files"
          align="center"
          label="次数限制（周）">
          <template slot-scope="scope">
            {{ scope.row.repair_files || '不限' }}
          </template>
        </el-table-column>
        <el-table-column width="120"
          align="center"
          label="打包限制（MB）">
          <template slot-scope="scope">
            {{ scope.row.package_limit || '不限' }}
          </template>
        </el-table-column>
        <el-table-column width="120"
          align="center"
          label="并行修复数（个）">
          <template slot-scope="scope">
            {{ scope.row.concurrent_limit || '1' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="125" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditInfo(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDelInfo(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="700px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="110px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="修复类型名称" prop="type_name">
              <el-input v-model="addForm.type_name" placeholder="请输入修复类型名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="修复类型ID" prop="file_type">
              <el-input :disabled="operType" type="number" v-model="addForm.file_type" placeholder="请输入修复类型ID"/>
            </el-form-item>
          </el-col>
        </el-row>   
        <el-row>
          <el-col :span="12">
            <el-form-item label="授权状态" prop="license">
              <el-radio-group :disabled="operType" v-model="addForm.license">
                  <el-radio :label="0">免费</el-radio>
                  <el-radio :label="1">付费</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>  
          <el-col :span="12">
            <el-form-item label="支持格式" prop="format">
                <el-input v-model="addForm.format" placeholder="如：.mp4,.mov"/>
            </el-form-item>
          </el-col>
        </el-row> 
        <el-row>
          <el-col :span="12">
            <el-form-item label="支持格式数量" prop="format_files">
              <el-input type="number" v-model="addForm.format_files" placeholder="请输入支持格式数量" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="大小限制" prop="upload_limit">
              <el-input type="number" v-model="addForm.upload_limit" placeholder="单位（MB），空为无限制" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="次数限制" prop="repair_files">
              <el-input type="number" v-model="addForm.repair_files" placeholder="次数（周），空为无限制" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="打包限制" prop="package_limit">
              <el-input type="number" v-model="addForm.package_limit" placeholder="大小（MB），0为无限制" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="并行修复数" prop="concurrent_limit">
              <el-input type="number" v-model="addForm.concurrent_limit" placeholder="并行数（个）" />
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddUserInfo" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { getRepairitLicense, setRepairitLicense, delLicense } from "@/api/productcfg"
  import {formatDate} from "@/utils/date"

  export default {
    name: "licenseConfigure",
    data() {
      return {
        user: {},
        form: {
          name: ''
        },
        title: "",     // 弹出层标题
        loading: true, // 表单数据加载中
        data: [],      // 用户列表
        page: 1,       // 当前页码
        pageSize: 15,  // 每页大小
        dialogFormVisible: false, // 控制对话框的显示隐藏
        operType: false, // 操作类型
        // 添加配置信息
        addForm:{
          type_name: "",
          file_type: "",
          license: "",
          format: "",
          format_files: "",
          upload_limit: "",
          repair_files: ""
        },
        addFormRules: {
          type_name: [{required: true, trigger: 'blur', message: "修复类型名称不能为空"}],
          file_type: [{required: true, trigger: 'blur', message: "修复类型ID不能为空"}],
          license: [{required: true, message: "请选择授权状态"}],
          format: [{required: true, trigger: 'blur', message: "支持格式不能为空"}],
          format_files: [{required: true, trigger: 'blur', message: "支持格式数量不能为空"}],
        },
        rowSpanArr: [],
        position: 0,
        fileTypeList: []
      }
    },
    created() {
      this.loadList()
      getRepairitLicense({page:1, count:1000}).then(res => {
        this.fileTypeList = res.data.results.map(item=>{
          return item.file_type + '|' + item.license
        })
      })
    },
    filters: {
      formatDate(time) {
        if (time === 0) return ""
        time = time * 1000
        let date = new Date(time)
        return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
      },
    },

    methods: {
      // 表单重置
      reset() {
        if(this.$refs['addFormRef']) this.$refs['addFormRef'].resetFields()
        this.addForm = {
          type_name: "",
          file_type: "",
          license: "",
          format: "",
          format_files: "",
          upload_limit: "",
          repair_files: "",
          package_limit: 0,
          concurrent_limit: 1
        }
      },
      loadList(page = 1) {
        this.loading = true
        getRepairitLicense({page, count: this.pageSize}).then(res => {
          this.data = res.data
          this.data.results.sort((a, b)=>{
            return a.file_type - b.file_type
          })
          this.data.results.sort((a, b)=>{
            if(a.file_type === b.file_type) {
              return a.license - b.license
            }
            return false
          })
          this.getRowSpan()
          //  关闭加载中
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      /**
       *  新增产品配置信息
       */
      onHandleAdd() {
        this.reset()
        this.operType = false
        this.dialogFormVisible = true
        this.title = "添加产品权益配置"
      },
      /**
       * 查询
       */
      onSearch() {
        this.loadList()
      },
      /**
       *  分页按钮响应交互
       */
      onCurrentChange(page) {
        this.loadList(page)
      },
      /**
     * 添加用户信息
     */
      onAddUserInfo() {
        this.$refs["addFormRef"].validate(valid => {
          if (valid) {
            let {file_type, type_name, license, upload_limit, repair_files, format, format_files, package_limit, concurrent_limit} = this.addForm
            if(this.fileTypeList.indexOf(file_type + '|' + license) !== -1 && !this.operType){//添加时校验重复
              this.$message({
                message: `已存在修复类型ID和授权状态相同的配置，不可重复添加`,
                type: 'warning'
              })
              return
            }
            this.loading = true
            setRepairitLicense({
              "data": {
                file_type: Number(file_type), 
                type_name, 
                license: Number(license), 
                upload_limit: Number(upload_limit), 
                repair_files: Number(repair_files), 
                format,
                format_files: Number(format_files),
                package_limit: Number(package_limit),
                concurrent_limit: Number(concurrent_limit)
              }
            }).then(res => {
              //  添加成功，刷新列表
              this.loadList(this.page)
              this.loading = false
              this.$message({
                message: `恭喜您，${this.operType ? '修改成功' : '添加成功'}`,
                type: 'success'
              });
              this.dialogFormVisible = false
            }).catch(err => {
              this.loading = false
              this.$message({
                message: `不好意思，${this.operType ? '修改失败' : '添加失败'}了哟，请重试`,
                type: 'warning'
              })
            })
            
          }
        })
      },
      /**
       *  修改产品权益信息
       */
      onEditInfo(data) {
        this.reset() 
        this.operType = true
        this.dialogFormVisible = true
        this.addForm = JSON.parse(JSON.stringify(data))
        this.title = "修改产品权益配置"
      },
      /**
       *  删除产品权益信息
       */
      onDelInfo(data) {
        this.$confirm('确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delLicense({
            version: "v2.0",
            file_type: data.file_type,
            license: data.license
          }).then(res => {
            //  删除成功
            this.loadList(this.page)
          })
        }).catch(() => {});
      },
      getRowSpan() {
        this.rowSpanArr = [];
        this.data.results.sort().forEach((item, index) => {
          if (index == 0) {
            this.rowSpanArr.push(1);
            this.position = 0;
          } else {
            if (this.data.results[index].file_type == this.data.results[index - 1].file_type) {
              this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
              this.rowSpanArr.push(0);
              this.data.results[index].file_type = this.data.results[index - 1].file_type;
            } else {
              this.rowSpanArr.push(1);
              this.position = index;
            }
          }
        });
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // 只合并区域位置
        if (columnIndex === 0) {
          const _row = this.rowSpanArr[rowIndex];
          return {
            rowspan: _row, //行
            colspan: 1 //列
          };
        }
      }
    },
  }
</script>

<style scoped>

  .list-table {
    margin-bottom: 10px;
  }
</style>
