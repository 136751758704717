<template>
  <div>
    <div class="user-info-head" @click="editCropper()">
      <el-avatar
        shape="circle"
        :size="100"
        fit="fit"
        :src="options.img"
      />
    </div>

    <el-dialog
      :title= "title"
      :visible.sync="open"
      width="800px"
      append-to-body
      @opened="modalOpened"
      @clost="closeDialog"
    >
      <el-row>
        <el-col :xs="24" :md="12" :style="{height: '320px'}">
          <vue-cropper
            ref="cropper"
            :img="options.img"
            :info="true"
            :autoCrop="options.autoCrop"
            :autoCropWidth="options.autoCropWidth"
            :autoCropHeight="options.autoCropHeight"
            :fixedBox="options.fixedBox"
            @realTime="realTime"
            v-if="visible"
          />
        </el-col>
        <el-col :xs="24" :md="12" :style="{height: '350px'}">
          <div class="preview-image-wrap">
            <img :src="previews.url" :style="previews.img" />
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="2" :md="2">
          <el-upload action="#" :http-request="requestUpload" :show-file-list="false" :before-upload="beforeUpload">
            <el-button size="small">
              选择
              <i class="el-icon-upload el-icon--right"></i>
            </el-button>
          </el-upload>
        </el-col>
        <el-col :lg="{span: 1, offset: 2}" :md="2">
          <el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
        </el-col>
        <el-col :lg="{span: 1, offset: 1}" :md="2">
          <el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
        </el-col>
        <el-col :lg="{span: 2, offset: 6}" :md="2">
          <el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
  import store from "@/store";
  import { VueCropper } from "vue-cropper";
  import {uploadAvatar} from "@/api/user"

  export default {
    name: "userAvatar",
    components: { VueCropper },
    props: {
      user: {
        type: Object
      }
    },
    data() {
      return {
        // 是否显示弹出层
        open: false,
        // 是否显示cropper
        visible: false,
        // 弹出层标题
        title: "修改头像",
        options: {
          img: store.getters.avatar, //裁剪图片的地址
          autoCrop: true, // 是否默认生成截图框
          autoCropWidth: 200, // 默认生成截图框宽度
          autoCropHeight: 200, // 默认生成截图框高度
          fixedBox: true // 固定截图框大小 不允许改变
        },
        previews: {
        }
      }
    },
    methods: {
      // 编辑头像
      editCropper() {
        this.open = true;
      },
      // 打开弹出层结束时的回调
      modalOpened() {
        this.visible = true;
      },
      // 覆盖默认的上传行为
      requestUpload() {
      },
      // 向左旋转
      rotateLeft() {
        this.$refs.cropper.rotateLeft();
      },
      // 向右旋转
      rotateRight() {
        this.$refs.cropper.rotateRight();
      },
      // 图片缩放
      changeScale(num) {
        num = num || 1;
        this.$refs.cropper.changeScale(num);
      },
      // 上传预处理
      beforeUpload(file) {
        if (file.type.indexOf("image/") == -1) {
          this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            console.log(file);
            this.options.img = reader.result;
          };
        }
      },
      // 上传图片
      uploadImg() {
        this.$refs.cropper.getCropBlob(data => {
          let formData = new FormData();
          formData.append("avatarfile", data);
          uploadAvatar(formData).then(response => {
            this.open = false;
            this.options.img = response.imgUrl;
            console.log(this.options.img);
            store.commit('SET_AVATAR', this.options.img);
            this.msgSuccess("修改成功");
            this.visible = false;
          });
        });
      },
      // 实时预览
      realTime(data) {
        this.previews = data ;
      },
      // 关闭窗口
      closeDialog() {
        this.options.img = store.getters.avatar
        console.log(this.options.img);
        this.visible = false;
      },
    }
  }
</script>

<style scoped lang="less">
.preview-image-wrap {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;
}
</style>
