<template>
  <div class nps-repairit-contarner>
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form" :model="form" label-width="68px">
        <el-form-item label="产品PID">
          <el-input v-model="pid"></el-input>
          <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{data.total}} 条结果</span>
        <div class="btn">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button>
        <upload-excel-comp :on-success="handleSuccess" :before-upload="beforeUpload" style="margin-left: 10px;  display: inline-block;"/></div>
      </div>
      <!--数据列表-->
      <el-table
        :data="data.results"
        :span-method="objectSpanMethod"
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="Pid"
          label="产品PID"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="UserType"
          align="center"
          label="用户类型">
          <template slot-scope="scope">
            {{scope.row.UserType === 0 ? "试用" : "付费" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="160"
          label="有添加视频时是否启用">
          <template slot-scope="scope">
            {{scope.row.popupstate.HasAddVideo === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="160"
          label="保存完成时启用">
          <template slot-scope="scope">
            {{scope.row.popupstate.HasSaveFile === 0 ? "否" : "是" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="popupstate.RSuccessValue"
          align="center"
          width="160"
          label="普通修复成功率（%）">
        </el-table-column>
        <el-table-column
          prop="popupstate.ADRSuccessValue"
          align="center"
          width="160"
          label="高级修复成功率（%）">
        </el-table-column>
        <el-table-column
          align="center"
          label="NpsMode">
          <template slot-scope="scope">
            {{ scope.row.nps.NpsMode === 0 ? "DayMode" : "SumMode" }}
          </template>
        </el-table-column>

        <el-table-column
          prop="nps.DayMode"
          align="center"
          label="DayMode">
        </el-table-column>
        <el-table-column
          prop="nps.SumMode"
          align="center"
          label="SumMode">
        </el-table-column>
        <el-table-column width="100" align="center"
          prop="create_by"
          :formatter="formatterEmpty"
          label="创建人">
        </el-table-column> 
        <el-table-column width="100" align="center"
          prop="update_by"
          :formatter="formatterEmpty"
          label="更新人">
        </el-table-column>
        <el-table-column
          prop="update_time"
          :formatter="formatterDateTime"
          align="center"
          width="140"
          label="更新时间">
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="onEditUserRate(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              circle
              icon="el-icon-delete"
              @click="onDeleteUserRate(scope.row.Pid, scope.row.UserType)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="data.total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="520px" append-to-body>
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="160px">
        <el-form-item label="用户PID: " prop="newPid">
          <el-input :disabled="operType" onkeyup="this.value = this.value.replace(/[^\d]/g,'');" maxlength="8" v-model="addForm.newPid" placeholder="请输入用户PID" style="width: 220px"/>
        </el-form-item>

        <el-form-item label="账户类型: ">
          <el-radio-group v-model="addForm.userType">
            <el-radio :disabled="operType" :label="2">All</el-radio>
            <el-radio :disabled="operType" :label="0">试用</el-radio>
            <el-radio :disabled="operType" :label="1">付费</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="NPS: " class="nps-mode">
          <el-radio-group v-model="addForm.npsMode">
            <el-form-item  prop="dayMode">
              <el-radio :label="0" >DayMode <el-input-number :min="1" v-model="addForm.dayMode" controls-position="right" placeholder="days" class="el-input" /></el-radio>
            </el-form-item>
            <el-form-item  prop="sumMode">
              <el-radio :label="1" >SumMode<el-input-number  :min="1" v-model="addForm.sumMode" controls-position="right" placeholder="days" class="el-input" /></el-radio>
            </el-form-item>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="普通修复成功率(%): " prop="rSuccessValue">
          <el-input-number :min="0" :max="150" v-model="addForm.rSuccessValue" placeholder="请输入普通修复成功率" style="width: 220px"/>
        </el-form-item>
        <el-form-item label="高级修复成功率(%): " prop="adrSuccessValue">
          <el-input-number :min="0" :max="150" v-model="addForm.adrSuccessValue" placeholder="请输入高级修复成功率" style="width: 220px"/>
        </el-form-item>

        <el-form-item label="有添加视频时是否启用: ">
          <el-checkbox v-model="addForm.hasAddVideo">HasAddVideo</el-checkbox>
        </el-form-item>

        <el-form-item label="保存完成时启用: ">
          <el-checkbox v-model="addForm.HasSaveFile">HasSaveFile</el-checkbox>
        </el-form-item>

      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onAddUserRate" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {addUserRate, delUserRate, getUserRates} from "@/api/rate";
  import {formatDate} from "@/utils/date"
  import UploadExcelComp from "@/components/common/UploadExcel"

  export default {
    name: "NpsRepairit",
    components: {
      UploadExcelComp
    },
    data() {
      return {
        form: {
          name: ''
        },
        title: "",     // 弹出层标题
        pid: null,     // 用户PID
        loading: true, // 表单数据加载中
        data: [],      // 用户评论列表
        page: 1,       // 当前页码
        pageSize: 20,  // 每页大小
        dialogFormVisible: false, // 控制对话框的显示隐藏
        operType: false, // 操作类型
        // 添加用户信息
        addForm:{
          newPid: null, // 产品PID
          userType: 2,  // 用户类型 0：试用类型 1：付费类型 2: All
          hasAddVideo: true,
          HasSaveFile: true,
          rSuccessValue: 0,
          adrSuccessValue: 0,
          // NPS配置
          npsMode: 0,   // nps弹窗模式 0：DayMode 1：SumMode
          dayMode: 1,   // 单日最多弹N次
          sumMode: 1,    // 在软件生命周期内最多弹N次
        },
        addFormRules: {
          newPid: [{required: true, trigger: 'blur', message: "PID不能为空"}],
          dayMode:[{required: true, trigger: 'blur', message: "DayMode不能为空"}],
          sumMode:[{required: true, trigger: 'blur', message: "SumMode不能为空"}],
          rSuccessValue:[{required: true, trigger: 'blur', message: "不能为空"}],
          adrSuccessValue:[{required: true, trigger: 'blur', message: "不能为空"}],
        }
      }
    },
    created() {
      this.loadNpsInfo()
    },
    methods: {
      // 表单重置
      reset() {
        this.addForm = {
          newPid: null, // 产品PID
          userType: 2,  // 用户类型 0：试用类型 1：付费类型 2: All
          hasAddVideo: true,
          HasSaveFile: true,
          rSuccessValue: 0,
          adrSuccessValue: 0,
          // NPS配置
          npsMode: 0,   // nps弹窗模式 0：DayMode 1：SumMode
          dayMode: 1,   // 单日最多弹N次
          sumMode: 1,    // 在软件生命周期内最多弹N次
        }
      },
      loadNpsInfo(page = 1) {
        this.loading = true
        getUserRates({
          "product_type": 1,
          page,
          "count": this.pageSize,
          "pid": this.pid != null && this.pid != "" ? Number(this.pid) : null
        }).then(res => {
          this.data = res.data
          this.getRowSpan()
          //  关闭加载中
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      },
      getRowSpan() {
        this.rowSpanArr = [];
        this.data.results.forEach((item, index) => {
          if (index == 0) {
            this.rowSpanArr.push(1);
            this.position = 0;
          } else {
            if (this.data.results[index].Pid == this.data.results[index - 1].Pid) {
              this.rowSpanArr[this.position] += 1; //项目名称相同，合并到同一个数组中
              this.rowSpanArr.push(0);
              this.data.results[index].Pid = this.data.results[index - 1].Pid;
            } else {
              this.rowSpanArr.push(1);
              this.position = index;
            }
          }
        });
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        // 只合并区域位置
        if (columnIndex === 0) {
          const _row = this.rowSpanArr[rowIndex];
          return {
            rowspan: _row, //行
            colspan: 1 //列
          };
        }
      },
      /**
       * 查询
       */
      onSearch() {
        this.pid == null ? null : this.loadNpsInfo()
      },
      /**
       *  分页按钮响应交互
       */
      onCurrentChange(page) {
        this.loadNpsInfo(page)
      },
      /**
       *  新增用户评价信息
       */
      onHandleAdd() {
        this.reset()
        this.operType = false
        this.dialogFormVisible = true
        this.title = "添加用户评分信息"
      },
      /**
       * 添加用户Nps信息
       */
      onAddUserRate() {
        this.$refs["addFormRef"].validate(valid => {
          if (valid) {
            this.loading = true
            if (this.addForm.userType == 2) {
              var res = new Array();

              for (var i = 0; i < 2; i++) {
                res[i] = {
                  "product_type": 1,
                  "data": {
                    "Pid": parseInt(this.addForm.newPid),
                    "UserType": i,
                    "nps": {
                      "NpsMode": this.addForm.npsMode,
                      "DayMode": this.addForm.dayMode,
                      "SumMode": this.addForm.sumMode,
                    },
                    "popupstate": {
                      "HasAddVideo": this.addForm.hasAddVideo ? 1 : 0,
                      "HasSaveFile": this.addForm.HasSaveFile ? 1 : 0,
                      "RSuccessValue": this.addForm.rSuccessValue,
                      "ADRSuccessValue": this.addForm.adrSuccessValue,
                    },
                  }
                }
              }
              var params = {
                "datas": res
              }

              addUserRate(params).then(res => {
                //  添加成功，刷新列表
                this.loadNpsInfo(this.page)
                this.dialogFormVisible = false
                this.loading = false
                this.$message({
                  message: '恭喜您，添加成功',
                  type: 'success'
                });
              }).catch(err => {
                this.loading = false
                this.$message({
                  message: '不好意思，添加失败了哟，请重试',
                  type: 'warning'
                });
              })
            } else {
              addUserRate({
                "product_type": 1,
                "data": {
                  "Pid": parseInt(this.addForm.newPid),
                  "UserType": this.addForm.userType,
                  "nps": {
                    "NpsMode": this.addForm.npsMode,
                    "DayMode": this.addForm.dayMode,
                    "SumMode": this.addForm.sumMode,
                  },
                  "popupstate":{
                    "HasAddVideo": this.addForm.hasAddVideo ? 1: 0,
                    "HasSaveFile": this.addForm.HasSaveFile ? 1: 0,
                    "RSuccessValue": this.addForm.rSuccessValue,
                    "ADRSuccessValue": this.addForm.adrSuccessValue,
                  },
                }

              }).then(res => {
                //  添加成功，刷新列表
                this.loadNpsInfo(this.page)
                this.dialogFormVisible = false
                this.loading = false
                this.$message({
                  message: this.operType ? '恭喜您，修改成功' : '恭喜您，添加成功',
                  type: 'success'
                });
              }).catch(err => {
                this.loading = false
                this.$message({
                  message: this.operType ? '不好意思，修改失败了哟，请重试' : '不好意思，添加失败了哟，请重试',
                  type: 'warning'
                });
              })
            }
          }
        })
      },
      /**
       *  修改用户评价信息
       */
      onEditUserRate(data) {
        this.reset()
        this.operType = true
        this.dialogFormVisible = true
        this.addForm.newPid = data.Pid
        this.addForm.userType = data.UserType
        this.addForm.hasAddVideo = data.popupstate.HasAddVideo ? true : false
        this.addForm.HasSaveFile = data.popupstate.HasSaveFile ? true : false
        this.addForm.rSuccessValue = data.popupstate.RSuccessValue
        this.addForm.adrSuccessValue = data.popupstate.ADRSuccessValue
        this.addForm.npsMode = data.nps.NpsMode
        this.addForm.dayMode = data.nps.DayMode
        this.addForm.sumMode = data.nps.SumMode
        this.title = "修改用户评分信息"
      },
      /**
       * 删除用户评价信息
       */
      onDeleteUserRate(pid, userType) {
        this.$confirm('确定删除吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delUserRate(1, pid, userType).then(res => {
            //  删除成功
            this.loadNpsInfo(this.page)
          })
        }).catch(() => {});
      },
      beforeUpload(file) {   //导入
        const isLt1M = file.size / 1024 / 1024 < 1
        if (isLt1M) {
          return true
        }
        this.$message({
          message: '请不要上传大于1m的文件!',
          type: 'warning'
        })
        return false
      },

      handleSuccess({ results, header }) {  //导入内容
        if (header[0] != "Pid" || header[1] != "UserType" || header[2] != "NpsMode" || header[3] != "DayMode" || header[4] != "SumMode" || header[5] != "HasAddVideo" || header[6] != "HasSaveFile" || header[7] != "RSuccessValue" || header[8] != "ADRSuccessValue")  {
          this.$message({
            message: '参数列表错误，请按对应格式进行导入【表头：Pid\tUserType\tNpsMode\tDayMode\tSumMode\tHasAddVideo\tHasSaveFile\tRSuccessValue\tADRSuccessValue】',
            type: 'error'
          })
          return
        }
        var res = new Array();

        results.forEach((item,index,results)=> {
          res[index] = {
            "product_type": 1,
            "data": {
              "Pid": results[index].Pid,
              "UserType": results[index].UserType,
              "nps": {
                "NpsMode": results[index].NpsMode,
                "DayMode": results[index].DayMode,
                "SumMode": results[index].SumMode,
              },
              "popupstate": {
                "HasAddVideo": results[index].HasAddVideo,
                "HasSaveFile": results[index].HasSaveFile,
                "RSuccessValue": results[index].RSuccessValue,
                "ADRSuccessValue": results[index].ADRSuccessValue,
              }
            }
          }
        })
        var params = {
          "datas": res
        }
        this.loading = true
        addUserRate(params).then(response => {
          //  添加成功，刷新列表
          this.loadNpsInfo(this.page)
          this.dialogFormVisible = false
          this.loading = false
          this.$message({
            type: 'success',
            message: '添加成功!'
          });
        }).catch(err => {
          this.loading = false
        })
      },
      //为空转换
      formatterEmpty(row, column, v){
        if (v || (v === 0)) return v
        return "-"
      },
      //时间转换
      formatterDateTime(row, column, v){
        if (!v) return "-"
        if(String(v).length === 10) v = v * 1000
        let date = new Date(v)
        return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
      }  
    }
  }
</script>

<style scoped>

  .btn {
    display: flex;
  }

  .nps-mode .el-input{
    width: 110px;
  }

  .list-table {
    margin-bottom: 10px;
  }
</style>
